import { useAuth } from '@/context/auth';
import AuthService from '@/services/auth.service';
import BroadcastService from '@/services/broadcast.service';
import NetworkService from '@/services/network.service';
import { AppState } from '@/types/store';
import { sentryCaptureException } from '@/utility';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { favoriteActions } from '../slice';
import { FavoriteProperty, FavoriteState } from '../types';

export async function fetchFavoriteProperties() {
  try {
    const service = new NetworkService();
    const result: FavoriteProperty[] = await service.get('/api/favorites');
    return result;
  } catch (err) {
    sentryCaptureException(err);
    return [];
  }
}

export async function fetchFavoritePropertyUserWise(propertyNumber: string) {
  try {
    const service = new NetworkService();
    const result = await service.get(
      `/api/favorites/userwise?propertyNumber=${propertyNumber}`
    );

    return result as Array<{
      email: string;
      name: string;
      isFavorite: boolean;
    }>;
  } catch (err) {
    sentryCaptureException(err);
    return [];
  }
}

export async function updateFavoriteById(
  propertyNumber: string,
  isFavorite: boolean,
  message?: string
) {
  const service = new NetworkService();
  await service.post(
    `/api/favorites?propertyNumber=${propertyNumber}&isFavorite=${isFavorite}&message=${encodeURIComponent(
      message || ''
    )}`,
    {}
  );
}

export async function updateFavoriteByIdForUser(
  customerId: string,
  propertyNumber: string,
  isFavorite: boolean,
  message?: string
) {
  const service = new NetworkService();
  await service.post(
    `/api/favorites?customerId=${customerId}&propertyNumber=${propertyNumber}&isFavorite=${isFavorite}&message=${encodeURIComponent(
      message || ''
    )}`,
    {}
  );
}

export interface UserPropertyResponse {
  isContextAvailable: boolean;
  isFavorite: boolean;
  toggleFavorite: () => void;
  loading: boolean;
}

const useFavoriteInternal = () => {
  const dispatch = useDispatch();
  const state = useSelector<AppState, FavoriteState>((state) => state.favorite);
  const { isAuthenticated, authInfo } = useAuth();

  const isFavoriteFn = useCallback(
    (propertyNumber: string) => {
      return !!state.favorites.find(
        (property) => property.propertyNumber === propertyNumber
      );
    },
    [state.favorites]
  );

  const toggleFavorite = useCallback(
    async (propertyNumber: string, message?: string) => {
      if (state.loading) return;

      if (!isAuthenticated) {
        const authService = new AuthService();
        authService.openAuthModel();
      } else {
        const isFavorite = isFavoriteFn(propertyNumber);

        if (isFavorite) {
          await updateFavoriteById(propertyNumber, false, message);
          dispatch(favoriteActions.removeFavorite(propertyNumber));
          BroadcastService.getInstance().broadcast(
            BroadcastService.EVENT.REMOVE_FAVORITE,
            propertyNumber
          );
        } else {
          await updateFavoriteById(propertyNumber, true, message);
          dispatch(favoriteActions.addFavorite(propertyNumber));
          BroadcastService.getInstance().broadcast(
            BroadcastService.EVENT.ADD_FAVORITE,
            propertyNumber
          );
        }
      }
    },
    [dispatch, state.loading, isFavoriteFn, isAuthenticated]
  );

  const showFavPopup = useCallback(
    (propertyNumber: string) => {
      dispatch(favoriteActions.setPopupPropertyNumber(propertyNumber));
    },
    [dispatch]
  );

  const handleFavoriteAction = useCallback(
    async (propertyNumber: string) => {
      if (authInfo?.permissions.includes('fav-admin')) {
        showFavPopup(propertyNumber);
      } else {
        await toggleFavorite(propertyNumber);
      }
    },
    [showFavPopup, toggleFavorite, authInfo]
  );

  return {
    favorites: state.favorites,
    loading: state.loading,
    isFavoriteFn,
    toggleFavorite,
    showFavPopup,
    handleFavoriteAction,
  };
};

export const useFavorite = () => {
  const { favorites, loading, isFavoriteFn, toggleFavorite } =
    useFavoriteInternal();

  return {
    favorites: favorites,
    loading: loading,
    isFavoriteFn,
    toggleFavorite,
  };
};

export const useSinglePropertyFavorite = (propertyNumber: string) => {
  const state = useSelector<AppState, FavoriteState>((state) => state.favorite);
  const {
    isFavoriteFn,
    showFavPopup,
    handleFavoriteAction: handleFavoriteActionInternal,
  } = useFavoriteInternal();
  const [isFavoriteUpdating, setIsFavoriteUpdating] = useState(false);

  const isFavorite = useMemo(
    () => isFavoriteFn(propertyNumber),
    [isFavoriteFn, propertyNumber]
  );

  const handleFavoriteAction = useCallback(async () => {
    setIsFavoriteUpdating(true);
    await handleFavoriteActionInternal(propertyNumber);
    setIsFavoriteUpdating(false);
  }, [handleFavoriteActionInternal, propertyNumber]);

  return {
    loading: state.loading,
    isFavorite,
    isFavoriteUpdating,
    showFavPopup,
    handleFavoriteAction,
  };
};
