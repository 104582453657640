import { AppObject } from '@/types';
import * as Sentry from '@sentry/nextjs';

export function transformObjectToQuery(object: AppObject) {
  return Object.keys(object)
    .filter((x) => object[x])
    .map((x) => {
      if (Array.isArray(object[x])) {
        return (object[x] as string[]).map((y) => `${x}=${y}`).join('&');
      }
      return `${x}=${object[x]}`;
    })
    .join('&');
}

export async function withRetry<T>(
  fn: () => Promise<T>,
  retries = 1,
  delay = 1000
): Promise<T> {
  try {
    return await fn();
  } catch (e) {
    if (retries === 0) {
      throw e;
    }
    return await new Promise((resolve) => {
      setTimeout(() => resolve(withRetry(fn, retries - 1, delay)), delay);
    });
  }
}

interface BuildingTypeMap {
  [key: string]: string[];
}

const BUILDING_TYPE_MAP: BuildingTypeMap = {
  Detached: [],
  'Semi-Detached': [],
  Townhouse: [],
  Apartment: [],
  Cottage: [],
  Land: [],
  Multiplex: [],
  Other: [],
};

export const buildingTypes = Object.keys(BUILDING_TYPE_MAP);
export function transformBuildingTypesForServer(
  buildingTypes: string[]
): string[] {
  return (buildingTypes || []).reduce((acc, curr) => {
    return acc.concat(BUILDING_TYPE_MAP[curr] || []);
  }, []);
}
export function getBuildingType(propertyType: string): string {
  if (!propertyType) return null;
  const buildingType = buildingTypes.find((x) =>
    BUILDING_TYPE_MAP[x].includes(propertyType)
  );
  return buildingType || null;
}

export function sentryCaptureException(error: Error) {
  Sentry.captureException(error);
}
