export interface ListItemProps
  extends React.DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  > {
  children: React.ReactNode;
}

export function ListItem({ children, className, ...rest }: ListItemProps) {
  return (
    <li className={className} {...rest}>
      {children}
    </li>
  );
}
