export const ACTION = {
  UPDATE_STATE: 'UPDATE_STATE',
  FILTER_UPDATE: '@FILTER/FILTER_UPDATE',
  CHANGE_VIEW: '@FILTER/CHANGE_VIEW',
  FILTER_CHANGE_TYPE: '@FILTER/CHANGE_TYPE',
  SET_PROPERTIES: '@PROPERTY/SET_PROPERTIES',
  SET_PROPERTY_TYPES: '@PROPERTY/SET_PROPERTY_TYPES',
  SET_PAGINATION: '@PROPERTY/SET_PAGINATION',
  SET_LOADING: '@PROPERTY/SET_LOADING',
  SET_HOVERED_PROPERTIES_INFO: '@PROPERTY/SET_HOVERED_PROPERTIES_INFO',
  SET_MAP_PROPERTIES: '@MAP/SET_MAP_PROPERTIES',
  SET_MAP_REFRESHING: '@MAP/SET_MAP_REFRESHING',
  SET_MAP_POSITION: '@MAP/SET_MAP_POSITION',
  SET_MAP_DEFAULT_POSITION: '@MAP/SET_MAP_DEFAULT_POSITION',
  SET_MAP_CURRENT_LOCATION: '@MAP/SET_MAP_CURRENT_LOCATION',
  SET_MAP_POLYGONS: '@MAP/SET_MAP_POLYGONS',
  SET_MAP_CLEAR_REF_NEIGHBORHOOD: '@MAP/SET_MAP_CLEAR_REF_NEIGHBORHOOD',
  SET_FAVORITE_PROPERTIES: '@PROPERTY/SET_FAVORITE_PROPERTIES',
  ADD_FAVORITE_PROPERTY: '@PROPERTY/ADD_FAVORITE_PROPERTY',
  REMOVE_FAVORITE_PROPERTY: '@PROPERTY/REMOVE_FAVORITE_PROPERTY',
  SET_MAP_DRAWING: '@MAP/SET_MAP_DRAWING',
};
