import clsx from 'clsx';
import { List } from '@/components/ui/lists';
import { ListItem } from '@/components/ui/listitem';
import { Link } from '@/components/ui/link';
import { SocialLinks } from '../social-links';
import IconCancel from '@/icons/icon-cancel';
import { useAuth } from '@/context/auth';
import AuthService from '@/services/auth.service';
import EventService from '@/services/event.service';
import router from 'next/router';
import React from 'react';
import Image from 'next/image';
import { useTranslation } from '@/hooks/use-translation';
import LanguageSwitch from '@/components/language-switch';

export interface SidenavProps {
  open: boolean;
  onClose: () => void;
}

const sidelinks = [
  {
    path: '/',
    name: 'Home',
  },
  {
    path: '/properties?type=sale',
    name: 'For Sale',
  },
  {
    path: '/properties?type=lease',
    name: 'For Rent',
  },
  {
    path: '/properties?type=sold',
    name: 'Sold',
  },
  {
    type: 'space',
  },
  // {
  //   path: '/video',
  //   name: 'Videos',
  // },
  {
    path: '/blog',
    name: 'Blog',
  },
  {
    path: '/contact',
    name: 'Contact us',
  },
  // {
  //   path: "/know-us",
  //   name: "Know Us",
  // },
  // {
  //   path: "/neighbourhood-guides",
  //   name: "Neighbourhood Guides",
  // },
];

export function Sidenav({ open, onClose }: SidenavProps) {
  const { loading, isAuthenticated, authInfo } = useAuth();
  const { t } = useTranslation();

  const handleSignout = () => {
    const authService = new AuthService();
    authService.signout();
    router.push('/');
    onClose();
  };

  const handleSignin = () => {
    onClose();
    EventService.emit('CHANGE_SIGNIN_MODEL_STATE', true);
  };

  const onClickCheckAuth = (e: React.MouseEvent) => {
    onClose();
    if (!isAuthenticated) {
      e.preventDefault();
      const authService = new AuthService();
      authService.openAuthModel();
    }
  };

  return (
    <div
      className={clsx(
        'fixed h-full z-50 bg-brown-500 top-0 overflow-x-hidden transform duration-200 right-0 bg-center bg-cover bg-no-repeat lg:w-180 w-full overflow-hidden',
        { 'right-0': open, '-right-full': !open }
      )}
      style={{
        // backgroundImage: "url('/img/buyingwith/reality-img.png')",
        zIndex: 1000,
      }}
    >
      <Image
        src={'/img/buyingwith/reality-img.png'}
        alt="buywintwith"
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
      <div className="absolute inset-0 bg-brown-500 opacity-60 z-10" />
      <div
        className="flex items-center justify-center flex-col h-full w-full z-30 relative"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
      >
        <div className="flex max-w-md w-full justify-end">
          <button
            onClick={onClose}
            className="justify-end items-end px-4 float-right text-white hover:text-black"
            aria-label="Menu"
          >
            <div className="relative h-6 w-6 md:h-8 md:w-8">
              <IconCancel width={40} height={40} />
            </div>
          </button>
        </div>
        <List className="flex items-center justify-center flex-col space-y-3">
          {sidelinks.map((x, i) => (
            <ListItem key={String(i)}>
              {x.type === 'space' ? (
                <div className="h-2 w-1" />
              ) : x.name !== 'Sold' && x.name !== 'My Favourties' ? (
                <Link
                  onClick={onClose}
                  href={x.path}
                  className="text-lg md:text-2xl font-semibold text-white hover:text-black duration-200 transform break-words"
                >
                  {t(x.name)}
                </Link>
              ) : (
                (x.name === 'Sold' || x.name === 'My Favourties') && (
                  <Link
                    href={x.path}
                    className="text-lg md:text-2xl font-semibold text-white hover:text-black duration-200 transform break-words"
                  >
                    <span onClick={onClickCheckAuth}>{t(x.name)}</span>
                  </Link>
                )
              )}
            </ListItem>
          ))}
        </List>

        {!loading &&
          (isAuthenticated ? (
            <div className="text-white mt-4 text-md md:text-xl">
              {authInfo.firstName},{' '}
              <button type="button" onClick={handleSignout}>
                {t('Sign Out')}
              </button>
            </div>
          ) : (
            <div className="text-white mt-4 text-md md:text-xl">
              <button type="button" onClick={handleSignin}>
                {t('Sign in')}
              </button>
            </div>
          ))}
        {isAuthenticated && (
          <List className="flex items-center justify-center flex-col space-y-3 mt-2">
            <ListItem>
              <Link
                href="/properties/favourite"
                className="text-lg md:text-lg text-white hover:text-black duration-200 transform break-words"
              >
                <span onClick={onClickCheckAuth}>{t('My Favourties')}</span>
              </Link>
            </ListItem>
            {authInfo.permissions.includes('realtor') && (
              <>
                <ListItem>
                  <Link
                    onClick={onClose}
                    href="/admin/listings"
                    className="text-lg md:text-lg text-white hover:text-black duration-200 transform break-words"
                  >
                    <span>{t('My Listings')}</span>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    onClick={onClose}
                    href="/profile"
                    className="text-lg md:text-lg text-white hover:text-black duration-200 transform break-words"
                  >
                    <span>{t('My Profile')}</span>
                  </Link>
                </ListItem>
              </>
            )}
          </List>
        )}
        <List className="flex items-center justify-center flex-col space-y-3 mt-2">
          <ListItem>
            <LanguageSwitch />
          </ListItem>
        </List>

        <SocialLinks className="mt-4 text-white" listItemClasses="mt-4" />
      </div>
    </div>
  );
}
