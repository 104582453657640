import clsx from 'clsx';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  margin?: 'dense' | 'normal';
  variant?: 'outlined' | 'default';
}

export function Input({
  className,
  label,
  variant,
  margin = 'normal',
  ...rest
}: InputProps) {
  return (
    <div className="flex w-full flex-col">
      {!!label && (
        <div className="mb-1 text-xs text-gray-400">
          <span>{label}</span>
        </div>
      )}
      <input
        {...rest}
        className={clsx('w-full outline-none rounded-md', {
          'px-4 py-3': margin === 'normal',
          'px-2 py-2': margin === 'dense',
          'border-primary-300 border-2 focus:outline-none  focus:border-primary-500':
            variant === 'outlined',
          'border ': variant === 'default',
        })}
      />
    </div>
  );
}
