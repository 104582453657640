import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './constant';
import { DialogData } from './types';

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState: initialState,
  reducers: {
    addDialog: (state, action: PayloadAction<DialogData>) => ({
      ...StaticRange,
      dialogs: [...state.dialogs, action.payload],
    }),
    removeDialog: (state, action: PayloadAction<string>) => ({
      ...state,
      dialogs: state.dialogs.filter((x) => x.id !== action.payload),
    }),
    setDialogData: (
      state,
      action: PayloadAction<Partial<DialogData> & Pick<DialogData, 'id'>>
    ) => {
      const updated = state.dialogs.map((x) => {
        if (x.id !== action.payload.id) {
          return x;
        }

        return {
          ...x,
          ...action.payload,
        };
      });

      return {
        ...state,
        dialogs: updated,
      };
    },
  },
});

export const dialogActions = dialogSlice.actions;
