import { Property } from '@/types';
import { ACTION } from './action';
import {
  IAction,
  IFilter,
  IFilterPropertyType,
  IMapPosition,
  IPagination,
  IPoint,
  IState,
  View,
} from './types';

export function reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case ACTION.UPDATE_STATE:
      return {
        ...state,
        ...(action.payload as IState),
      };
    case ACTION.FILTER_CHANGE_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          type: action.payload as IFilterPropertyType,
        },
      };
    case ACTION.SET_PROPERTIES:
      return {
        ...state,
        data: {
          ...state.data,
          properties: action.payload as Property[],
        },
      };
    case ACTION.SET_PAGINATION:
      return {
        ...state,
        data: {
          ...state.data,
          pagination: action.payload as IPagination,
        },
      };
    case ACTION.SET_LOADING:
      return {
        ...state,
        data: {
          ...state.data,
          refreshing: action.payload as boolean,
        },
      };
    case ACTION.SET_HOVERED_PROPERTIES_INFO:
      return {
        ...state,
        ...(action.payload as Pick<
          IState,
          'hoveredProperties' | 'hoveredArea'
        >),
      };
    case ACTION.SET_MAP_PROPERTIES:
      return {
        ...state,
        map: {
          ...state.map,
          properties: action.payload as Property[],
        },
      };
    case ACTION.SET_MAP_REFRESHING:
      return {
        ...state,
        map: {
          ...state.map,
          refreshing: action.payload as boolean,
        },
      };
    case ACTION.SET_MAP_POSITION:
      return {
        ...state,
        map: {
          ...state.map,
          position: action.payload as IMapPosition,
        },
      };
    case ACTION.SET_MAP_DEFAULT_POSITION:
      return {
        ...state,
        map: {
          ...state.map,
          defaultPosition: action.payload as IMapPosition,
        },
      };
    case ACTION.SET_MAP_CURRENT_LOCATION:
      return {
        ...state,
        map: {
          ...state.map,
          currentLocation: action.payload as IPoint,
        },
      };
    case ACTION.SET_MAP_POLYGONS:
      return {
        ...state,
        map: {
          ...state.map,
          polygons: action.payload as [number, number][],
          drawing: state.map.drawing ? null : state.map.drawing,
        },
      };
    case ACTION.SET_MAP_DRAWING:
      return {
        ...state,
        filter: {
          ...state.filter,
          city: state.filter.city ? null : state.filter.city,
          neighborhood: state.filter.neighborhood
            ? null
            : state.filter.neighborhood,
        },
        map: {
          ...state.map,
          polygons: [],
          drawing: action.payload,
        },
      };
    case ACTION.FILTER_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...(action.payload as IFilter),
          page: (action.payload as IFilter).page || 1,
        },
      };
    case ACTION.SET_MAP_CLEAR_REF_NEIGHBORHOOD:
      if (!state.map.refPropertyInfo) return state;
      return {
        ...state,
        map: {
          ...state.map,
          refPropertyInfo: {
            ...state.map.refPropertyInfo,
            neighborhood: null,
          },
        },
      };
    case ACTION.CHANGE_VIEW:
      return {
        ...state,
        view: action.payload as View,
      };
    default:
      return state;
  }
}
