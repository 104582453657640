import CompareIcon from '@/icons/compare';
import { useCompare } from '@/context/compare/hooks/use-compare';
import { ClickAway } from '../ui/click-away';
import { Link } from '../ui/link';
import { useAuth } from '@/context/auth';
import clsx from 'clsx';
import { useState } from 'react';
import KeyboardArrowLeftIcon from '@/icons/keyboard-arrow-left';
import Favorites from '@/icons/favorite';
import BookmarkIcon from '@/icons/bookmark';
import { SaveSearchListModal } from '@/components/modals';
import { useTranslation } from '@/hooks/use-translation';

export default function FloatingAction() {
  const [open, setOpen] = useState(false);
  const [openListModal, setOpenListModal] = useState(false);
  const { isAuthenticated } = useAuth();
  const { items } = useCompare();
  const { t } = useTranslation();

  if (!isAuthenticated) return null;

  return (
    <>
      <div
        className={clsx(
          'fixed top-0 bottom-0 w-24 z-20 transition-all',
          open ? 'right-0' : '-right-24'
        )}
        style={{
          background: 'linear-gradient(to left, #00000055, #00000000)',
        }}
      />
      <ClickAway onClickAway={() => setOpen(false)}>
        <div className="fixed bottom-40 md:bottom-20 right-0 z-20">
          <button
            aria-label="Quick actions"
            className={clsx(
              'w-16 h-10 bg-primary-400 hover:bg-primary-500 absolute top-0 text-white rounded-l-full px-2 transition-all shadow-xl',
              open ? 'right-0' : '-right-6 hover:right-0'
            )}
            onClick={() => setOpen(!open)}
          >
            <KeyboardArrowLeftIcon
              className={clsx('w-6 h-6 transition-all', open && 'rotate-180')}
            />
          </button>
          <div
            className={clsx(
              'absolute bottom-0 right-6 mb-4 transition-all',
              !open && 'translate-x-16'
            )}
          >
            <Link
              href={`/properties/compare?propertyNumbers=${items
                .map((x) => x.propertyNumber)
                .join(',')}`}
              title={t('Compare')}
              className="flex items-center justify-center w-10 h-10 bg-primary-400 hover:bg-primary-500 text-white rounded-full px-2 shadow-xl"
              onClick={() => setOpen(false)}
            >
              <CompareIcon className="h-6 w-6" />
              {Boolean(items.length) && (
                <span className="absolute inline-flex h-5 w-5 rounded-full bg-primary-600 text-white text-xs -top-1 -right-1  items-center justify-center">
                  {items.length}
                </span>
              )}
            </Link>
            <Link
              href="/properties/favourite"
              title={t('My Favourites')}
              className="flex items-center justify-center w-10 h-10 bg-primary-400 hover:bg-primary-500 text-white rounded-full px-2 shadow-xl mt-2"
              onClick={() => setOpen(false)}
            >
              <Favorites className="h-6 w-6" />
            </Link>
            <button
              title={t('Saved Search')}
              className="w-10 h-10 bg-primary-400 hover:bg-primary-500 text-white rounded-full px-2 mt-2 shadow-xl"
              onClick={() => setOpenListModal(true)}
            >
              <BookmarkIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </ClickAway>
      <SaveSearchListModal
        open={openListModal}
        toggleModal={() => setOpenListModal((prev) => !prev)}
      />
    </>
  );
}
