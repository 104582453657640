import { AppState } from '@/types/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compareActions } from '../slice';
import { CompareState } from '../types';

export default function useStateUpdater() {
  const dispatch = useDispatch();
  const state = useSelector<AppState, CompareState>((state) => state.compare);

  useEffect(() => {
    const compareItemsString = localStorage.getItem('compareItems');
    let compareItems = [];

    if (compareItemsString) {
      compareItems = JSON.parse(compareItemsString);
    }

    dispatch(compareActions.setItems(compareItems));
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem('compareItems', JSON.stringify(state.items));
  }, [state.items]);
}
