import React, { SVGProps } from 'react';

export default function BuildingIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 480 480"
      fill="currentColor"
      {...props}
    >
      <path
        d="M472,464h-48V200c0-4.418-3.582-8-8-8H304V8c0-4.418-3.582-8-8-8H88c-4.418,0-8,3.582-8,8v456H8c-4.418,0-8,3.582-8,8
				s3.582,8,8,8h464c4.418,0,8-3.582,8-8S476.418,464,472,464z M168,464h-32v-72c0-4.418,3.582-8,8-8h16c4.418,0,8,3.582,8,8V464z
				 M200,200v264h-16v-72c0-13.255-10.745-24-24-24h-16c-13.255,0-24,10.745-24,24v72H96V16h192v176h-80
				C203.582,192,200,195.582,200,200z M408,464H216V208h192V464z"
      />
      <path
        d="M256,112h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C264,115.582,260.418,112,256,112z
				 M248,160h-32v-32h32V160z"
      />
      <path
        d="M256,32h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V40C264,35.582,260.418,32,256,32z
				 M248,80h-32V48h32V80z"
      />
      <path
        d="M176,112h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C184,115.582,180.418,112,176,112z
				 M168,160h-32v-32h32V160z"
      />
      <path
        d="M176,32h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V40C184,35.582,180.418,32,176,32z
				 M168,80h-32V48h32V80z"
      />
      <path
        d="M176,192h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C184,195.582,180.418,192,176,192z
				 M168,240h-32v-32h32V240z"
      />
      <path
        d="M176,272h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C184,275.582,180.418,272,176,272z
				 M168,320h-32v-32h32V320z"
      />
      <path
        d="M328,368h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C320,364.418,323.582,368,328,368z
				 M336,320h32v32h-32V320z"
      />
      <path
        d="M328,288h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C320,284.418,323.582,288,328,288z
				 M336,240h32v32h-32V240z"
      />
      <path
        d="M328,448h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C320,444.418,323.582,448,328,448z
				 M336,400h32v32h-32V400z"
      />
      <path
        d="M248,368h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C240,364.418,243.582,368,248,368z
				 M256,320h32v32h-32V320z"
      />
      <path
        d="M248,288h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C240,284.418,243.582,288,248,288z
				 M256,240h32v32h-32V240z"
      />
      <path
        d="M248,448h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C240,444.418,243.582,448,248,448z
				 M256,400h32v32h-32V400z"
      />
    </svg>
  );
}
