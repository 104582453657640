import clsx from 'clsx';

import style from './hero.module.css';

export interface HeroProps {
  backgroundImage?: string;
  title?: string;
}

export function Hero({
  backgroundImage = '/img/knowus/bg.png',
  title,
}: HeroProps) {
  return (
    <div
      className={style.hero}
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    >
      <div className="px-8">
        <h2
          className={clsx(
            'text-2xl md:text-5xl relative inline-flex font-extrabold md:font-semibold z-10 leading-normal mt-6',
            style['hero-title']
          )}
        >
          {title}
        </h2>
      </div>
    </div>
  );
}
