import LanguageSwitch from '@/components/language-switch';
import { Image } from '@/components/ui/image';
import { Link } from '@/components/ui/link';
import { useAuth } from '@/context/auth';
import AuthService from '@/services/auth.service';
import React, { useState } from 'react';
import { Sidenav } from '../sidenav';
import { DesktopLinks } from './constants';

interface Navbar2Props {
  title: string;
}

export function Navbar2(props: Navbar2Props) {
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useAuth();

  const toggleSidebar = () => setOpen((prev) => !prev);
  const closeSidebarIfOpen = () => {
    if (open) setOpen(false);
  };

  const onClickSold = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      const authService = new AuthService();
      authService.openAuthModel();
    }
  };

  return (
    <div>
      <Sidenav open={open} onClose={toggleSidebar} />
      <nav className="flex items-center flex-wrap px-8 py-4 z-10">
        <div className="flex items-center justify-between gap-0 flex-1  md:gap-3">
          <div className="flex flex-1 w-full">
            <div className="">
              <Link
                href="/"
                className="md:flex-1 flex items-center justify-center md:items-start md:justify-start"
                onClick={closeSidebarIfOpen}
                prefetch={false}
              >
                <div className="relative w-10 md:w-10 h-10 md:h-10">
                  <Image
                    src="/icon-192x192.png"
                    alt="logo"
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
              </Link>
            </div>
            <div className="flex flex-1 max-w-2xl ml-3 items-center">
              <h4 className="text-xl font-bold ml-3 text-gray-700">
                {props.title}
              </h4>
            </div>
          </div>

          <ul className="flex items-center space-x-3 md:space-x-8">
            {DesktopLinks.map((item) => (
              <li key={item.path} className="hidden md:flex">
                {item.name !== 'Sold' && (
                  <Link
                    href={item.path}
                    className="text-lg transform duration-200 border-b-2 pb-2 border-transparent hover:border-primary-500 font-medium hover:text-primary-500"
                  >
                    {item.name}
                  </Link>
                )}
                {item.name === 'Sold' && (
                  <Link
                    href={item.path}
                    className="text-lg transform duration-200 border-b-2 pb-2 border-transparent hover:border-primary-500 font-medium hover:text-primary-500 "
                  >
                    <span onClick={onClickSold}>{item.name}</span>
                  </Link>
                )}
              </li>
            ))}
            <li className="hidden md:block">
              <LanguageSwitch />
            </li>
            <li>
              <button onClick={toggleSidebar} style={{ marginTop: -5 }}>
                <span style={{ fontSize: '0.7rem', marginBottom: -10 }}>
                  MENU
                </span>
                <div
                  style={{ transform: 'scaleX(-1)', marginTop: -10 }}
                  className="h-8 w-8 transform -rotate-180"
                >
                  <Image
                    src="/img/iconimg.png"
                    layout="fill"
                    objectFit="contain"
                    alt="Icon Image"
                  />
                </div>
              </button>
            </li>
          </ul>
        </div>
        <div className="hidden md:flex items-center md:mt-0 mt-4">
          {/* <div className="pr-8">
            <p className="text-base mb-2">
              CALL OR TEXT US{" "}
              <a
                href="tel: 647-546-9775"
                className="font-semibold text-sm md:text-base"
                title="Call : 647-546-9775"
              >
                {InfoJson.contactNo}
              </a>
            </p>
            <SocialLinks />
          </div> */}
          <button onClick={toggleSidebar} className="flex md:hidden">
            <div
              style={{ transform: 'scaleX(-1)' }}
              className="h-10 w-10 transform -rotate-180"
            >
              <Image
                src="/img/iconimg.png"
                layout="fill"
                objectFit="contain"
                alt="Icon Image"
              />
            </div>
          </button>
        </div>
      </nav>
    </div>
  );
}
