import clsx from 'clsx';

import style from './list.module.css';

export interface ListProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  > {
  children?: React.ReactNode;
  type?: ListType;
}

export type ListType = 'checked' | 'square';
export function List({ children, type, className, ...rest }: ListProps) {
  return (
    <ul
      className={clsx(
        style.list,
        {
          [style.checkedList]: type === 'checked',
          [style.squareList]: type === 'square',
        },
        className
      )}
      {...rest}
    >
      {children}
    </ul>
  );
}
