import fetch from 'node-fetch';
import { AbortController, AbortSignal } from 'node-abort-controller';
import * as Sentry from '@sentry/nextjs';

import config from '@/config';
import { AppObject } from '@/types';

/**
 * @description Base class
 */
export default class NetworkService {
  private async handleError(response: Response) {
    if (response.ok) return;

    try {
      const codeMapping = [
        {
          code: 400,
          message: 'Bad Request',
        },
        {
          code: 401,
          message: 'Unauthorized',
        },
        {
          code: 403,
          message: 'Forbidden',
        },
        {
          code: 404,
          message: 'Not Found',
        },
        {
          code: 500,
          message: 'Internal Server Error',
        },
      ];

      for (const { code, message } of codeMapping) {
        if (response.status === code) {
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            const data = await response.json();
            if (data.error) {
              throw new Error(data.error);
            }
          }

          throw new Error(response.statusText || message);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  /**
   * @param url
   * @description Get Method
   */
  public async get<T>(url: string, signal?: AbortSignal): Promise<T> {
    const response: Response = await fetch(`${config.API_URL}${url}`, {
      headers: this.getHeaders(),
      method: 'GET',
      signal,
    });

    await this.handleError(response);

    const result = await response.json();

    return result as T;
  }

  public getCancelToken() {
    const controller = new AbortController();
    return controller;
  }

  /**
   * @param url
   * @param body
   * @description Post Method
   */
  public async post<T = object>(url: string, body: T) {
    const response: Response = await fetch(`${config.API_URL}${url}`, {
      headers: this.getHeaders(),
      method: 'POST',
      body: JSON.stringify(body),
    });

    await this.handleError(response);
    return response.json();
  }

  /**
   * @param url
   * @param body
   * @description Patch Method
   */
  public async patch<T = object>(url: string, body: T) {
    const response: Response = await fetch(`${config.API_URL}${url}`, {
      headers: this.getHeaders(),
      method: 'PATCH',
      body: JSON.stringify(body),
    });
    await this.handleError(response);
    return response.json();
  }

  /**
   * @param url
   * @description Delete Method
   */
  public async delete(url: string) {
    const response: Response = await fetch(`${config.API_URL}${url}`, {
      headers: this.getHeaders(),
      method: 'DELETE',
    });

    await this.handleError(response);
    return response.json();
  }

  /**
   * @param url
   * @param body
   * @description Put Method
   */
  public async put<T = object>(url: string, body: T) {
    const response: Response = await fetch(`${config.API_URL}${url}`, {
      headers: this.getHeaders(),
      method: 'PUT',
      body: JSON.stringify(body),
    });

    await this.handleError(response);
    return response.json();
  }

  public transformObjectToQuery(obj: AppObject): string {
    return Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join('&');
  }

  private getHeaders() {
    return {
      'content-type': 'application/json',
    };
  }
}
