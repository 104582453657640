import { AppObject } from '@/types';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

export default function useRouteQuery() {
  const router = useRouter();
  const routerRef = useRef(router);

  useEffect(() => {
    routerRef.current = router;
  }, [router]);

  const updateQuery = useCallback(
    (
      query: AppObject,
      replace?: boolean,
      shallow?: boolean,
      pathname?: string
    ) => {
      let url = pathname || routerRef.current.pathname;

      Object.entries(routerRef.current.query).forEach((item) => {
        const key = item[0];
        const value = item[1];
        if (url.indexOf(`[${key}]`) !== -1) {
          url = url.replace(`[${key}]`, value as string);
        }
      });

      const queryItems = Object.keys(query)
        .map((key) => ({
          key,
          value: query[key],
        }))
        .filter((item) => item.value !== undefined && item.value !== null);

      if (queryItems.length) {
        url +=
          `?${ 
          queryItems
            .map(
              (item) =>
                `${item.key}=${encodeURIComponent(item.value as string)}`
            )
            .join('&')}`;
      }

      if (replace) {
        routerRef.current.replace(url, undefined, { shallow });
      } else {
        routerRef.current.push(url, undefined, { shallow });
      }
    },
    [routerRef]
  );

  return {
    query: router.query,
    router,
    updateQuery,
  };
}
