import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constant';
import { EnabledState } from './types';

export const rootSlice = createSlice({
  name: 'root',
  initialState: initialState,
  reducers: {
    setEnabledState: (state, action) => {
      return {
        ...state,
        enabledStates: action.payload as EnabledState[],
      };
    },
  },
});

export const rootActions = rootSlice.actions;
