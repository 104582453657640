import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { default as useAuthStateUpdater } from '../auth/hooks/use-state-updater';
import useCompareStateUpdater from '../compare/hooks/use-state-updater';
import { default as useFavoriteStateUpdater } from '../favorite/hooks/use-state-updater';
import { rootActions } from './rootSlice';
import { EnabledState } from './types';

export const AppProvider: React.FC = ({ children }) => {
  useAuthStateUpdater();
  useFavoriteStateUpdater();
  useCompareStateUpdater();

  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (router.pathname) {
      case '/properties':
        dispatch(rootActions.setEnabledState(['favorite'] as EnabledState[]));
        break;
      case '/detail/[id]/[slug]':
      case '/detail/[id]':
        dispatch(rootActions.setEnabledState(['favorite'] as EnabledState[]));
        break;
      case '/properties/favourite':
        dispatch(rootActions.setEnabledState(['favorite'] as EnabledState[]));
        break;
      default:
        dispatch(rootActions.setEnabledState([]));
        break;
    }
  }, [dispatch, router.pathname]);

  return <>{children}</>;
};
