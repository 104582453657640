import {
  getApiLinkQuery,
  transformObjectToQuery,
  transformProperty,
} from '@/utility';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useRef } from 'react';
import { ACTION } from '../action';
import { IAction, IState } from '../types';
import { useAuth } from '@/context/auth';
import { useRouter } from 'next/router';

export const useDataLoader = (
  state: IState,
  dispatch: React.Dispatch<IAction>
) => {
  const { authInfo } = useAuth();
  const router = useRouter();
  const routerRef = useRef(router);
  const { initialized, filter, view, map } = state;
  const ref = useRef(false);
  const requestRef = useRef<CancelTokenSource>(null);

  useEffect(() => {
    routerRef.current = router;
  }, [router]);

  useEffect(() => {
    if (!initialized) return;
    if (!ref.current) {
      ref.current = true;
      return;
    }

    if (filter.page > 5 && !authInfo?.subscribed) {
      routerRef.current.replace('/subscribe');
      return;
    }

    (async () => {
      if (requestRef.current) {
        requestRef.current.cancel('Request cancelled');
      }

      const query = getApiLinkQuery(
        {
          type: filter.type,
          // class: filter.class,
          propertyType: filter.propertyType,
          city: filter.city,
          district: filter.district,
          neighborhood: filter.neighborhood,
          keywords: filter.keywords,
          minPrice: filter.minPrice,
          maxPrice: filter.maxPrice,
          minBeds: filter.minBeds,
          maxBeds: filter.maxBeds,
          minBaths: filter.minBaths,
          maxBaths: filter.maxBaths,
          furnishing: filter.furnishing,
          map: filter.map,
          page: filter.page,
          sortBy: filter.sortBy,
          listedInDays: filter.listedInDays,
        },
        filter.page,
        view,
        map.drawing
      );

      dispatch({
        type: ACTION.SET_LOADING,
        payload: true,
      });

      const cancelToken = axios.CancelToken;
      requestRef.current = cancelToken.source();

      try {
        const response = await axios
          .get(`/api/repliers/listings?${transformObjectToQuery(query)}`, {
            cancelToken: requestRef.current.token,
          })
          .then((x) => x.data);

        dispatch({
          type: ACTION.SET_PROPERTIES,
          payload: (response.listings || []).map(transformProperty),
        });

        dispatch({
          type: ACTION.SET_PAGINATION,
          payload: {
            count: response.count || 0,
            numPages: response.numPages || 0,
          },
        });

        dispatch({
          type: ACTION.SET_LOADING,
          payload: false,
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    })();
  }, [
    dispatch,
    initialized,
    filter.type,
    // filter.class,
    filter.propertyType,
    filter.map,
    filter.page,
    filter.city,
    filter.district,
    filter.neighborhood,
    filter.keywords,
    filter.minPrice,
    filter.maxPrice,
    filter.minBeds,
    filter.maxBeds,
    filter.minBaths,
    filter.maxBaths,
    filter.sortBy,
    filter.listedInDays,
    filter.furnishing,
    routerRef,
    authInfo,
    view,
    map.drawing,
  ]);

  return null;
};
