import NetworkService from '@/services/network.service';
import { AppObject } from '@/types';
import { IPropertyResponseItem, transformObjectToQuery } from '@/utility';
import { useEffect, useRef, useState } from 'react';

export interface ICity {
  name: string;
  state?: string;
  city?: string;
  country?: string;
  key: string;
}

interface IAutocompleteResponse {
  cities: ICity[];
  properties: IPropertyResponseItem[];
}

export interface GoogleCityResult {
  description: string;
  name?: string;
  state?: string;
  country?: string;
}

const client = new NetworkService();

export default function useCityResult(value: string): [ICity[], boolean] {
  const [results, setResults] = useState<ICity[]>([]);
  const [loading, setLoading] = useState(false);
  const cancelToken = useRef(client.getCancelToken());

  useEffect(() => {
    if (!value) {
      setResults([]);
      setLoading(false);
      return;
    }

    (async () => {
      try {
        setLoading(true);
        if (cancelToken?.current) {
          cancelToken.current.abort();
        }
        cancelToken.current = client.getCancelToken();
        if (!value) {
          setResults([]);
          setLoading(false);
          return;
        }

        const query: AppObject = {
          q: value,
        };

        query.include = ['city'];

        setLoading(true);
        const response = await client.get<IAutocompleteResponse>(
          `/api/listing/autocomplete?${transformObjectToQuery(query)}`,
          cancelToken.current.signal
        );

        setResults(response.cities);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [value]);

  return [results, loading];
}
