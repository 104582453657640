import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

interface SwtichOption {
  value: string;
  label: string;
}

interface SwitchProps {
  onChange: (value: string) => void;
  value: string;
  options: [SwtichOption, SwtichOption];
}

const Switch: React.FC<SwitchProps> = (props) => {
  const handleChange = () => {
    props.onChange(
      props.value === props.options[0].value
        ? props.options[1].value
        : props.options[0].value
    );
  };

  const selectedOption = useMemo(
    () =>
      props.options.find((x) => x.value === props.value) || props.options[0],
    [props.options, props.value]
  );
  const isFirst = useMemo(
    () => props.value === props.options[0].value,
    [props.value, props.options]
  );

  return (
    <div
      className="flex flex-row items-center justify-around w-20 h-8 bg-primary-50 rounded-2xl relative border-2 border-primary-200"
      onClick={handleChange}
      role="button"
    >
      <div className="flex items-center justify-center h-8 w-10 text-sm font-bold">
        {props.options[0].label}
      </div>
      <div className="flex items-center justify-center h-8 w-10 text-sm font-bold">
        {props.options[1].label}
      </div>
      <div
        style={{ marginTop: '0px', marginLeft: '0px' }}
        className={clsx(
          'flex items-center justify-center bg-primary-400 rounded-full text-white text-sm font-bold h-7 w-10 inset-0 absolute z-20',
          {
            'translate-x-10 duration-1000': !isFirst,
            'translate-x-0 duration-1000': isFirst,
          }
        )}
      >
        {selectedOption.label}
      </div>
    </div>
  );
};

const SwitchOptions: [SwtichOption, SwtichOption] = [
  {
    label: 'SO',
    value: 'so',
  },
  {
    label: 'EN',
    value: 'en',
  },
];

const LanguageSwitch: React.FC = () => {
  const router = useRouter();

  const handleChange = (value: string) => {
    router.replace(router.asPath, router.asPath, { locale: value });
  };

  return (
    <div className="relative">
      <div className="flex justify-between items-center lg:space-x-4 flex-row mt-4 mb-4">
        <div className="rounded-2xl relative z-10">
          <Switch
            value={router.locale}
            onChange={handleChange}
            options={SwitchOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitch;
