import BroadcastService from '@/services/broadcast.service';
import { AppState } from '@/types/store';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { favoriteActions } from '../slice';
import { fetchFavoriteProperties } from './use-favorite';

export default function useStateUpdater() {
  const appState = useSelector<AppState, AppState>((state) => state);
  const dispatch = useDispatch();

  const isEnabled = useMemo(() => {
    if (!appState.auth.isAuthenticated) return false;
    return appState.root.enabledStates.includes('favorite');
  }, [appState.auth.isAuthenticated, appState.root.enabledStates]);

  useEffect(() => {
    if (!isEnabled) {
      dispatch(favoriteActions.setFavorites([]));
      dispatch(favoriteActions.setLoading(false));
    } else {
      dispatch(favoriteActions.setLoading(true));
      (async () => {
        const favorites = await fetchFavoriteProperties();
        dispatch(favoriteActions.setFavorites(favorites));
        dispatch(favoriteActions.setLoading(false));
      })();
    }
  }, [dispatch, isEnabled]);

  useEffect(() => {
    const unsubscribe = BroadcastService.getInstance().subscribe(function (
      type,
      payload
    ) {
      if (type === BroadcastService.EVENT.ADD_FAVORITE) {
        const propertyNumber = payload as unknown as string;
        dispatch(favoriteActions.addFavorite(propertyNumber));
      } else if (type === BroadcastService.EVENT.REMOVE_FAVORITE) {
        const propertyNumber = payload as unknown as string;
        dispatch(favoriteActions.removeFavorite(propertyNumber));
      }
    });

    return function clean() {
      unsubscribe();
    };
  }, [dispatch]);
}
