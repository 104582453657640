import { IState } from './types';

export const DEFAULT_LATITUDE = 2.039873364916381;
export const DEFAULT_LANGITUDE = 45.329742281481394;
export const DEFAULT_ZOOM = 14;

const DEFAULT_BOUNDS_SW_LNG = 45.27137741331733;
const DEFAULT_BOUNDS_SW_LAT = 2.067321544848994;
const DEFAULT_BOUNDS_NE_LNG = 45.38810714964546;
const DEFAULT_BOUNDS_NE_LAT = 2.0124247166278937;

export const DEFAULT_BOUNDS: [number, number, number, number] = [
  DEFAULT_BOUNDS_SW_LNG,
  DEFAULT_BOUNDS_SW_LAT,
  DEFAULT_BOUNDS_NE_LNG,
  DEFAULT_BOUNDS_NE_LAT,
];

export const initialState: IState = {
  initialized: false,
  view: 'list',
  filter: {
    type: 'sale',
    // class: 'residential',
    propertyType: [],
    city: null,
    neighborhood: null,
    district: null,
    keywords: null,
    minPrice: null,
    maxPrice: null,
    minBeds: null,
    maxBeds: null,
    minBaths: null,
    maxBaths: null,
    map: null,
    page: 1,
    sortBy: null,
  },
  map: {
    skipCurrentLocation: false,
    properties: [],
    refreshing: false,
    position: {
      lat: null,
      lng: null,
      zoom: null,
    },
    defaultPosition: {
      lat: DEFAULT_LATITUDE,
      lng: DEFAULT_LANGITUDE,
      zoom: DEFAULT_ZOOM,
    },
    currentLocation: null,
    refPropertyInfo: null,
    polygons: [],
    drawing: null,
  },
  data: {
    pagination: {
      count: 0,
      numPages: 1,
    },
    properties: [],
    refreshing: false,
  },
  hoveredProperties: [],
  hoveredArea: null,
  // boards: [],
};
