import { useCallback, useEffect } from 'react';
import AuthService, { AUTH_KEY } from '@/services/auth.service';
import EventService from '@/services/event.service';
import { AuthState } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../slice';
import { AppState } from '@/types/store';
import BroadcastService from '@/services/broadcast.service';
import NetworkService from '@/services/network.service';

export default function useStateUpdater() {
  const dispatch = useDispatch();
  const state = useSelector<AppState, AuthState>((state) => state.auth);

  const handleAuthChange = useCallback(() => {
    const jdrAuth = localStorage.getItem(AUTH_KEY);

    if (!jdrAuth) {
      dispatch(
        authActions.updateState({
          loading: false,
          isAuthenticated: false,
          authInfo: null,
          hasTableViewAccess: false,
        } as AuthState)
      );
      return;
    }

    try {
      const jdrAuthParsed = JSON.parse(jdrAuth);

      if (Number(jdrAuthParsed.expiresIn) * 1000 < Date.now()) {
        throw new Error('Session expired');
      }

      dispatch(
        authActions.updateState({
          loading: false,
          isAuthenticated: true,
          authInfo: jdrAuthParsed,
          hasTableViewAccess: jdrAuthParsed.hasTableViewAccess,
        } as AuthState)
      );
    } catch (error) {
      console.error(error);
      localStorage.removeItem(AUTH_KEY);
      dispatch(
        authActions.updateState({
          loading: false,
          isAuthenticated: false,
          authInfo: null,
          hasTableViewAccess: false,
        } as AuthState)
      );
    }
  }, [dispatch]);

  useEffect(() => {
    handleAuthChange();
  }, [handleAuthChange]);

  useEffect(() => {
    function onAuthChange() {
      dispatch(
        authActions.updateState({
          loading: true,
        } as Partial<AuthState>)
      );
      handleAuthChange();
    }

    EventService.addListener(EventService.EVENT.AUTH_CHANGE, onAuthChange);

    return function clean() {
      EventService.removeListener(EventService.EVENT.AUTH_CHANGE, onAuthChange);
    };
  }, [dispatch, handleAuthChange]);

  useEffect(() => {
    const hasTableViewAccess =
      !state.loading &&
      state.isAuthenticated &&
      state.authInfo &&
      Array.isArray(state.authInfo.permissions) &&
      state.authInfo.permissions.includes('table-view');

    dispatch(
      authActions.updateState({
        hasTableViewAccess,
      } as Partial<AuthState>)
    );
  }, [dispatch, state.isAuthenticated, state.authInfo, state.loading]);

  useEffect(() => {
    const unsubscribe = BroadcastService.getInstance().subscribe(function (
      type
    ) {
      if (type === BroadcastService.EVENT.AUTH_CHANGE) {
        EventService.emit(EventService.EVENT.AUTH_CHANGE);
      }
    });

    return function clean() {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (state.loading) return;
    if (!state.isAuthenticated) {
      dispatch(
        authActions.updateState({ preferenceLoading: false, preference: null })
      );
      return;
    }

    (async () => {
      const service = new NetworkService();
      return; // TODO: remove this line
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await service.get('/api/my-preference');

      if (result.NotFound || !result.data) {
        dispatch(
          authActions.updateState({
            preferenceLoading: false,
            preference: null,
          })
        );
        return;
      } else {
        dispatch(
          authActions.updateState({
            preferenceLoading: false,
            preference: {
              budget: {
                prefer: result.data.budgetPrefer,
                limit: result.data.budgetLimit,
                higherIsBetter: false,
              },
              bathroom: {
                prefer: result.data.bathroomPrefer,
                limit: result.data.bathroomLimit,
                higherIsBetter: true,
              },
              bedroom: {
                prefer: result.data.bedroomPrefer,
                limit: result.data.bedroomLimit,
                higherIsBetter: true,
              },
              parking: {
                prefer: result.data.parkingPrefer,
                limit: result.data.parkingLimit,
                higherIsBetter: true,
              },
              annualTax: {
                prefer: result.data.annualTaxPrefer,
                limit: result.data.annualTaxLimit,
                higherIsBetter: false,
              },
              maintenanceFee: {
                prefer: result.data.maintenanceFeePrefer,
                limit: result.data.maintenanceFeeLimit,
                higherIsBetter: false,
              },
              propertyType: {
                prefer: result.data.propertyTypePrefer || [],
                limit: result.data.propertyTypeLimit || [],
              },
            },
          } as Partial<AuthState>)
        );
      }
      // console.log(result);
    })();
  }, [dispatch, state.isAuthenticated, state.loading]);

  useEffect(() => {
    if (state.loading) return;
    if (!state.isAuthenticated) {
      return;
    }
    if (!state.authInfo?.email) return;

    const authService = new AuthService();
    authService.flushVisitedPropertyInQuey(state.authInfo.email);
  }, [state.isAuthenticated, state.loading, state.authInfo?.email]);
}
