export function toNumber(value: string): number | null {
  if (!value) return null;
  if (value === 'null' || value === 'N/A') return null;
  return Number(value);
}

const defaultFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function formatCurrency(amount: number, formatter?: Intl.NumberFormat) {
  return (formatter || defaultFormatter).format(amount);
}

export function cleanObject<T>(obj: T): T {
  return Object.keys(obj).reduce((acc, key) => {
    if (
      !Array.isArray(obj[key]) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== ''
    ) {
      acc[key] = obj[key];
    } else if (Array.isArray(obj[key]) && obj[key].length) {
      acc[key] = obj[key];
    }
    return acc;
  }, {}) as T;
}

export function formatCash(input?: number) {
  const n = input || 0;
  if (n < 1e3) return String(n);
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)  }K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)  }M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)  }B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)  }T`;
}
