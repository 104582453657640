import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constant';
import { AuthState } from './types';

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    updateState: (state, action) => {
      return {
        ...state,
        ...(action.payload as AuthState),
      };
    },
  },
});

export const authActions = authSlice.actions;
