import EventEmitter from 'event-emitter';

const TYPE = {
  AUTH_CHANGE: '@event/AUTH_CHANGE',
};

class EventService {
  public EVENT = TYPE;
  private emitter = EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit(eventName: string, ...args: any[]) {
    this.emitter.emit(eventName, ...args);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addListener(eventName: string, fn: (...args: any[]) => void) {
    this.emitter.on(eventName, fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeListener(eventName: string, fn: (...args: any[]) => void) {
    this.emitter.off(eventName, fn);
  }
}

export default new EventService();
