import { ListedInDaysOptions } from '@/components/listing/search-bar/data';
import config from '@/config';
import {
  Drawing,
  IFilter,
  IFilterPropertyType,
  IMapPosition,
  MapBound,
  View,
} from '@/context/property';
import { AppObject, Property, TLastStatus } from '@/types';

import { transformObjectToQuery } from '../common.utility';
import {
  FORMAT_DATE4,
  addDays,
  dateDiffInDays,
  formatDate,
  subtractDays,
  subtractMonths,
  subtractYears,
} from '../date';
import { cleanObject, formatCash, toNumber } from './helpers';
import {
  FEATURE_TRANSFORM_HANDLERS,
  getAddressLine1,
  getAddressLine2,
  getPropertyClass,
  getPropertyName,
  getSlug,
  groupRooms,
  transformArea,
  transformBathroomString,
  transformBedroomString,
  transformFeatures,
  transformLandSize,
  transformParkingString,
  transformPropertyTypeString,
  transformRooms,
} from './transforms';
import { IPropertyResponseItem } from './types';

const _transformProperty = (
  property: IPropertyResponseItem,
  locale: string,
  keepAddress = false
): Property => {
  if (!keepAddress) {
    securePropertyAddress(property);
  }

  let closedDate: string = null;
  if (
    property.soldDate &&
    property.daysOnMarket &&
    !Number.isNaN(property.daysOnMarket) &&
    property.boardId === 17
  ) {
    if (
      Math.abs(
        dateDiffInDays(property.soldDate, property.listDate) -
          Number(property.daysOnMarket)
      ) > 5
    ) {
      closedDate = property.soldDate;
      property.soldDate = addDays(
        property.listDate,
        Number(property.daysOnMarket)
      ).toISOString();
    }
  }

  const name = getPropertyName(property);
  return {
    propertyNumber: property.propertyNumber,
    thumbnail: property.images ? `${config.CDN_URL}/${property.images[0]}` : '',
    listPrice: toNumber(property.listPrice),
    soldPrice: toNumber(property.soldPrice),
    soldDate: property.soldDate?.split('T')[0] || '',
    closedDate,
    brokerageName: property?.office?.brokerageName,
    bath: toNumber(property?.details?.numBathrooms),
    beds: toNumber(property?.details?.numBedrooms),
    bedsPlus: toNumber(property?.details?.numBedroomsPlus),
    bathsPlus: toNumber(property?.details?.numBathroomsPlus),
    id: property.propertyNumber,
    name,
    address: property.address,
    addressline1: getAddressLine1(property),
    addressline2: getAddressLine2(property),
    slug: getSlug(name),
    squareMtr: property?.details?.sqmt || 'N/A',
    parking: toNumber(property?.details?.numParkingSpaces),
    description: property?.details?.description || '',
    extras: property?.details?.extras || '',
    propertyClass: getPropertyClass(property?.class),
    annualTaxes: toNumber(property?.taxes?.annualAmount),
    amenties: (property?.condominium?.ammenities || []).filter((x) => x),
    nearByAmenties: (property?.nearby?.ammenities || []).filter((x) => x),
    map: {
      lat: Number(property?.map?.latitude),
      lng: Number(property?.map?.longitude),
    },
    images: (property?.images || []).map((x) => `${config.CDN_URL}/${x}`),
    virtualTourUrl: property?.details?.virtualTourUrl || '',
    comparables: (property?.comparables || [])
      .filter(
        (x) => x.images?.length && x.propertyNumber !== property.propertyNumber
      )
      .map((x) => transformProperty(x, locale)),
    type: property?.type,
    status: property?.status || null,
    listDate: property?.listDate || '',
    lastStatus: property?.lastStatus,
    rooms: groupRooms(transformRooms(property?.rooms)),
    areaString: transformArea(property),
    landSizeString: transformLandSize(property),
    bedroomString: transformBedroomString(property, locale),
    bathroomString: transformBathroomString(property, locale),
    parkingString: transformParkingString(property),
    daysOnMarket: property?.daysOnMarket || '',
    boardId: property?.boardId || null,
    propertyTypeString: transformPropertyTypeString(property),
    lot: !property.lot
      ? null
      : {
          depth: property.lot.depth || null,
          width: property.lot.width || null,
          measurement: property.lot.measurement || null,
        },
    history: property.history || [],
    permissions: property.permissions || null,
    features: transformFeatures(property),
    propertyType: property.details?.propertyType || null,
    style: FEATURE_TRANSFORM_HANDLERS.style(property.details?.style, property),
    maintanceFee: toNumber(property.condominium?.fees.maintenance),
    updatedOn: property.updatedOn || '',
    agent: property.agents?.[0] || null,
    deposit: toNumber(property?.details?.deposit),
  };
};

export function transformProperty(
  property: IPropertyResponseItem,
  locale: string
) {
  return _transformProperty(property, locale, false);
}

export function transformPropertyWithAddress(
  property: IPropertyResponseItem,
  locale: string
) {
  return _transformProperty(property, locale, true);
}

export function generatePaginationData2(page: number, numPages: number) {
  const previousPageNumbers = new Array(2)
    .fill(0)
    .map((_, index) => page - index - 1)
    .filter((x) => x > 0 && x < page)
    .sort((a, b) => a - b);

  const nextPageNumbers = new Array(5 - previousPageNumbers.length + 1)
    .fill(0)
    .map((_, index) => page + index)
    .filter((x) => x > page && x <= numPages)
    .sort((a, b) => a - b);

  const nextPageNumber = page + 1;

  const previousPageNumber = page - 1;

  return {
    previousPageNumbers,
    nextPageNumbers,
    nextPageNumber,
    previousPageNumber,
  };
}

export function queryToFilter(query: AppObject): Partial<IFilter> {
  let mapBounds: MapBound;

  if (query.map) {
    mapBounds = (query.map as string)
      .split(',')
      .map(Number)
      .filter((x) => !isNaN(x)) as MapBound;

    if (mapBounds.length !== 8) {
      console.error('Invalid map bounds');
      mapBounds = null;
    }
  }

  return {
    type: query.type as IFilterPropertyType,
    // class: query.class as IFilterPropertyClass,
    propertyType:
      (query.propertyType as string)?.split(',').filter((x) => !!x) || [],
    city: query.city as string,
    neighborhood: query.neighborhood as string,
    district: query.district as string,
    furnishing: query.furnishing as string,
    keywords: query.keywords as string,
    minPrice: query.minPrice ? Number(query.minPrice) : null,
    maxPrice: query.maxPrice ? Number(query.maxPrice) : null,
    minBeds: query.minBeds ? Number(query.minBeds) : null,
    maxBeds: query.maxBeds ? Number(query.maxBeds) : null,
    minBaths: query.minBaths ? Number(query.minBaths) : null,
    maxBaths: query.maxBaths ? Number(query.maxBaths) : null,
    sortBy: query.sortBy as string,
    listedInDays: query.listedInDays ? Number(query.listedInDays) : null,
    map: mapBounds,
    page: query.page ? Number(query.page) : 1,
  };
}

export function queryToPageNumber(query: AppObject): number {
  return query.page ? Number(query.page) : 1;
}

type IQuery = {
  type: string;
  // class?: string;
  city: string;
  neighborhood: string;
  district: string;
  keywords: string;
  minPrice: number;
  maxPrice: number;
  minBeds: number;
  maxBeds: number;
  minBaths: number;
  maxBaths: number;
  sortBy: string;
  view: string;
  zoom: number;
  lat: number;
  lng: number;
  page: number;
  map: string;
} & AppObject;

export function filterToQuery(
  filter: IFilter,
  view: View,
  map: IMapPosition
): IQuery {
  return cleanObject({
    type: filter.type,
    // class: filter.class,
    propertyType: filter.propertyType?.join(','),
    city: filter.city,
    neighborhood: filter.neighborhood,
    district: filter.district,
    keywords: filter.keywords,
    minPrice: filter.minPrice > 0 ? filter.minPrice : null,
    maxPrice: filter.maxPrice > 0 ? filter.maxPrice : null,
    minBeds: filter.minBeds > 0 ? filter.minBeds : null,
    maxBeds: filter.maxBeds > 0 ? filter.maxBeds : null,
    minBaths: filter.minBaths > 0 ? filter.minBaths : null,
    maxBaths: filter.maxBaths > 0 ? filter.maxBaths : null,
    furnishing: filter.furnishing,
    sortBy: filter.sortBy,
    listedInDays: filter.listedInDays,
    view,
    zoom: map.zoom,
    lat: map.lat,
    lng: map.lng,
    page: filter.page > 1 ? filter.page : null,
    map: filter.map ? filter.map.join(',') : null,
  });
}

export function getLink(query: IQuery) {
  return `/properties?${transformObjectToQuery(query)}`;
}

export function getPageLink(query: IQuery, pageNum: number) {
  return getLink({
    ...query,
    pageNum,
  });
}

export function getApiLinkQuery(
  filter: Partial<IFilter>,
  page: number,
  view: View,
  drawing: Drawing
): AppObject {
  const query: AppObject = {
    hasImages: true,
    resultsPerPage: 20,
    // class: filter.class || 'residential',
    type: filter.type || 'sale',
    propertyType: filter.propertyType,
    city: filter.city,
    district: filter.district,
    neighborhood: filter.neighborhood,
    keywords: filter.keywords,
    minPrice: filter.minPrice,
    maxPrice: filter.maxPrice,
    minBeds: filter.minBeds,
    maxBeds: filter.maxBeds,
    minBaths: filter.minBaths,
    maxBaths: filter.maxBaths,
    furnishing: filter.furnishing,
    pageNum: page > 1 ? page : null,
    minListDate: filter.listedInDays
      ? formatDate(
          subtractDays(new Date(), filter.listedInDays - 1),
          FORMAT_DATE4
        )
      : null,
    sortBy: filter.sortBy,
  };

  if (filter.map) {
    const [neLng, neLat, seLng, seLat, swLng, swLat, nwLng, nwLat] = filter.map;
    query.map = JSON.stringify([
      [
        [neLng, neLat],
        [seLng, seLat],
        [swLng, swLat],
        [nwLng, nwLat],
      ],
    ]);
  }

  if (filter.type === 'sold') {
    query.type = 'sale';
    query.status = 'U';
    query.lastStatus = 'Sld';
    query.minSoldDate = formatDate(subtractYears(new Date(), 1), FORMAT_DATE4);
  }

  if (filter.type === 'leased') {
    query.type = 'lease';
    query.status = 'U';
    query.lastStatus = 'Lsd';
    query.minSoldDate = formatDate(subtractMonths(new Date(), 3), FORMAT_DATE4);
  }

  if (view === 'list' && (filter.type === 'sold' || filter.type === 'leased')) {
    query.maxSoldDate = formatDate(new Date(), FORMAT_DATE4);
  }

  if (drawing) {
    if (drawing.circle) {
      query.lat = drawing.circle.lat;
      query.long = drawing.circle.lng;
      query.radius = Math.ceil(drawing.circle.radius / 1000);
    } else if (drawing.polygons) {
      query.map = JSON.stringify(drawing.polygons);
    }
  }

  return query;
}

export function getMapApiLink(
  filter: Partial<IFilter>,
  drawing: Drawing
): AppObject {
  const query: AppObject = {
    hasImages: true,
    resultsPerPage: 1000,
    // class: filter.class || 'residential',
    type: filter.type || 'sale',
    propertyType: filter.propertyType,
    city: filter.city,
    district: filter.district,
    neighborhood: filter.neighborhood,
    keywords: filter.keywords,
    minPrice: filter.minPrice,
    maxPrice: filter.maxPrice,
    minBeds: filter.minBeds,
    maxBeds: filter.maxBeds,
    minBaths: filter.minBaths,
    maxBaths: filter.maxBaths,
    furnishing: filter.furnishing,
    fields: 'map.*,propertyNumber,details,condominium',
    minListDate: filter.listedInDays
      ? formatDate(
          subtractDays(new Date(), filter.listedInDays - 1),
          FORMAT_DATE4
        )
      : null,
  };

  if (filter.map) {
    const [neLng, neLat, seLng, seLat, swLng, swLat, nwLng, nwLat] = filter.map;
    query.map = JSON.stringify([
      [
        [neLng, neLat],
        [seLng, seLat],
        [swLng, swLat],
        [nwLng, nwLat],
      ],
    ]);
  }

  if (filter.type === 'sold') {
    query.type = 'sale';
    query.status = 'U';
    query.lastStatus = 'Sld';
    query.minSoldDate = formatDate(subtractYears(new Date(), 1), FORMAT_DATE4);
  }

  if (filter.type === 'leased') {
    query.type = 'lease';
    query.status = 'U';
    query.lastStatus = 'Lsd';
    query.minSoldDate = formatDate(subtractMonths(new Date(), 3), FORMAT_DATE4);
  }

  if (drawing) {
    if (drawing.circle) {
      query.lat = drawing.circle.lat;
      query.long = drawing.circle.lng;
      query.radius = Math.ceil(drawing.circle.radius / 1000);
    } else if (drawing.polygons) {
      query.map = JSON.stringify(drawing.polygons);
    }
  }

  return query;
} // based on filter for fetch map data

export function getLastStatusText(lastStatus: TLastStatus) {
  switch (lastStatus) {
    case 'Sld':
      return 'Sold';
    case 'Lsd':
      return 'Leased';
    case 'Exp':
      return 'Expired';
    case 'Ter':
      return 'Terminated';
    case 'New':
      return 'New';
    case 'Dft':
      return 'Draft';
    case 'Sc':
      return 'Sold Conditional';
    default:
      return lastStatus;
  }
}

export function securePropertyAddress(property: unknown) {
  if (!(property as AppObject).address) return;
  const permissions = (property as AppObject<AppObject>)
    .permissions as AppObject;

  if (!permissions) return;

  if (permissions.displayAddressOnInternet === 'Y') {
    return;
  }

  (property as AppObject<AppObject>).address = {
    area: '',
    zip: '',
    country: null,
    city: '',
    streetNumber: '',
    unitNumber: '',
    streetDirection: '',
    streetName: 'Address not available',
    district: '',
    streetSuffix: '',
    neighborhood: '',
    state: '',
    majorIntersection: '',
    communityCode: '',
  };
}

export function getSaveSearchTitle(filter: IFilter): string {
  let title = '';

  let isActive = true;
  if (filter.type === 'sold' || filter.type === 'leased') {
    isActive = false;
    title += `${filter.type} `;
  }

  if (filter.propertyType?.length) {
    const propertyTypes = [...filter.propertyType];
    const lastItem = propertyTypes[propertyTypes.length - 1];
    if (lastItem === 'Detached' || lastItem === 'Semi-Detached') {
      propertyTypes[propertyTypes.length - 1] = `${lastItem} houses`;
    } else if (lastItem === 'Townhouse') {
      propertyTypes[propertyTypes.length - 1] = `${lastItem}s`;
    }
    title += propertyTypes.join(', ');
  } else {
    title += 'Property';
  }

  if (isActive) {
    title += ' for ';
    title += filter.type;
  }

  if (filter.city) {
    title += ' in ';
    title += filter.city;
  }

  if (filter.neighborhood) {
    title += ' in ';
    title += filter.neighborhood;
  }

  if (!filter.minPrice && filter.maxPrice) {
    title += ` under $${formatCash(filter.maxPrice)}`;
  } else if (filter.minPrice && !filter.maxPrice) {
    title += ` above $${formatCash(filter.minPrice)}`;
  } else if (filter.minPrice && filter.maxPrice) {
    title += ` between $${formatCash(filter.minPrice)} and $${formatCash(
      filter.maxPrice
    )}`;
  }

  if (filter.minBeds || filter.minBaths) {
    title += ' with ';
    const strs = [];
    if (filter.minBeds) {
      if (filter.minBeds === filter.maxBeds) {
        strs.push(`${filter.minBeds} beds`);
      } else {
        strs.push(`${filter.minBeds}+ beds`);
      }
    }
    if (filter.minBaths) {
      if (filter.minBaths === filter.maxBaths) {
        strs.push(`${filter.minBaths} baths`);
      } else {
        strs.push(`${filter.minBaths}+ baths`);
      }
    }
    title += strs.join(' and ');
  }

  if (filter.keywords) {
    title += ` has keyword '${filter.keywords}'`;
  }

  if (filter.listedInDays) {
    title += ' listed ';
    title += ListedInDaysOptions.find(
      (x) => x.value === String(filter.listedInDays)
    )?.label;
  }

  title += '.';

  title = title.charAt(0).toUpperCase() + title.slice(1);

  return title;
}
