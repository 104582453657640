import React, { SVGProps } from 'react';

export default function IconFacebook(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32 16.9808H26.2104V13.1406C26.2104 11.6984 27.1555 11.3622 27.8212 11.3622C28.4853 11.3622 31.9069 11.3622 31.9069 11.3622V5.02221L26.2801 5C20.0338 5 18.6123 9.72859 18.6123 12.7546V16.9808H15V23.5138H18.6123C18.6123 31.898 18.6123 42 18.6123 42H26.2104C26.2104 42 26.2104 31.7984 26.2104 23.5138H31.3374L32 16.9808Z"
        fill="currentColor"
      />
    </svg>
  );
}
