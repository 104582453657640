import React from 'react';
import clsx from 'clsx';
import Spinner from '@/icons/spinner';

export type ButtonColor = 'primary' | 'error';

export type ButtonMargin = 'dense' | 'normal';

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: React.ReactNode;
  color?: ButtonColor;
  margin?: ButtonMargin;
  loading?: boolean;
}

export function Button({
  children,
  color = 'primary',
  margin = 'normal',
  className,
  loading,
  disabled,
  ...rest
}: ButtonProps) {
  return (
    <button
      disabled={disabled || loading}
      className={clsx(
        'text-white font-medium text-sm flex items-center justify-center',
        {
          'bg-primary-600 hover:bg-primary-500': color === 'primary',
          'bg-red-500 hover:bg-red-400': color === 'error',
          'py-2 px-8': margin === 'dense',
          'py-3 px-10': margin === 'normal',
          'bg-gray-400 hover:bg-gray-400 cursor-not-allowed': disabled,
        },
        className
      )}
      {...rest}
    >
      {loading && <Spinner className="w-4 sm:w-5 mr-2 spin" />}
      <span>{children}</span>
    </button>
  );
}
