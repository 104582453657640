import { v4 as uuidv4 } from 'uuid';

const TYPE = {
  ADD_FAVORITE: '@broadcast/ADD_FAVORITE',
  REMOVE_FAVORITE: '@broadcast/REMOVE_FAVORITE',
  AUTH_CHANGE: '@broadcast/AUTH_CHANGE',
};

const BROADCAST_KEY = 'broadcast';
type SubscribeFn = <T>(type: string, payload: T) => void;

class BroadcastService {
  public static EVENT = TYPE;
  private uniqueId: string;
  private messageKey: string;
  private static instance: BroadcastService;
  private listeners: SubscribeFn[] = [];

  public static getInstance() {
    if (!BroadcastService.instance) {
      BroadcastService.instance = new BroadcastService();
    }
    return BroadcastService.instance;
  }

  constructor() {
    this.uniqueId = uuidv4();
    this.messageKey = `${BROADCAST_KEY}-${this.uniqueId}`;
    window.addEventListener('storage', this.handleStorageChangeEvent);

    // clear expired data
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(BROADCAST_KEY)) {
        const dataString = JSON.stringify(localStorage.getItem(key));
        const data = JSON.parse(dataString);
        if (data.timestamp + 10000 < Date.now()) {
          localStorage.removeItem(key);
        }
      }
    });
  }

  private handleStorageChangeEvent = (event: StorageEvent) => {
    if (!event.key) return;
    if (!event.key.startsWith(BROADCAST_KEY)) return;
    if (event.key === this.messageKey) return;

    const dataString = localStorage.getItem(event.key);
    if (!dataString) return;

    const data = JSON.parse(dataString);

    this.listeners.forEach((listener) => listener(data.type, data.payload));
  };

  private setData<T>(data: T) {
    localStorage.setItem(this.messageKey, JSON.stringify(data));
  }

  broadcast<T>(type: string, payload: T) {
    const data = {
      type,
      payload,
      timestamp: Date.now(),
    };

    this.setData(data);

    setTimeout(() => {
      localStorage.removeItem(this.messageKey);
    }, 1000);
  }

  subscribe(fn: SubscribeFn) {
    this.listeners.push(fn);

    return () => {
      this.listeners = this.listeners.filter((listener) => listener !== fn);
    };
  }
}

export default BroadcastService;
