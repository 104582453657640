import { Property, PropertyFeature, PropertyRoom } from '@/types';
import { getBuildingType } from '../common.utility';
import { formatCurrency, toNumber } from './helpers';
import { IPropertyResponseItem } from './types';
import { translate } from '@/hooks/use-translation';

export function transformArea(property: IPropertyResponseItem): string {
  if (property?.details?.sqmt) {
    return `${property.details.sqmt} sqmt.`;
  }

  // if (property?.lot) {
  //   const { width, depth, measurement } = property.lot;

  //   return `${width} X ${depth} ${measurement}`;
  // }

  return '';
}

export function transformLandSize(property: IPropertyResponseItem): string {
  if (property?.lot && property?.lot.width && property?.lot.depth) {
    const { width, depth, measurement } = property.lot;

    return `${width} X ${depth} ${measurement || ''}`;
  }

  return '';
}

export function transformBedroomString(
  property: IPropertyResponseItem,
  locale: string
): string {
  if (!property?.details?.numBedrooms && !property?.details?.numBedroomsPlus) {
    return '';
  }

  if (
    property?.details?.numBedrooms &&
    (!property?.details?.numBedroomsPlus ||
      property?.details?.numBedroomsPlus === '0')
  ) {
    return translate(locale, '{count} Beds', {
      count: property.details.numBedrooms,
    });
  }

  return translate(locale, '{count} Beds', {
    count: `${property.details.numBedrooms || '0'}+`,
  });
  // return translate(locale, '{count} Beds', {
  //   count: `${property.details.numBedrooms || '0'}+${
  //     property?.details?.numBedroomsPlus
  //   }`,
  // });
}

export function transformBathroomString(
  property: IPropertyResponseItem,
  locale: string
): string {
  if (
    (!property?.details?.numBathrooms ||
      property?.details?.numBathrooms === '0') &&
    !property?.details?.numBathroomsPlus
  ) {
    return '';
  }

  if (property?.details?.numBathrooms && !property?.details?.numBathroomsPlus) {
    return translate(locale, '{count} Baths', {
      count: property.details.numBathrooms,
    });
  }

  return translate(locale, '{count} Baths', {
    count: `${property.details.numBathrooms || '0'}+${
      property?.details?.numBathroomsPlus
    }`,
  });
}

export function transformParkingString(
  property: IPropertyResponseItem
): string {
  if (property?.details?.numParkingSpaces) {
    if (!isNaN(Number(property?.details?.numParkingSpaces))) {
      if (!Number(property?.details?.numParkingSpaces)) {
        return '';
      }

      return String(Number(property?.details?.numParkingSpaces));
    }

    return property?.details?.numParkingSpaces;
  }

  return '';
}

export function transformRooms(rooms: object): PropertyRoom[] {
  if (!rooms) {
    return [];
  }
  return Object.values(rooms)
    .map((room) => ({
      description: room.description,
      features: [room.features, room.features2, room.features3].filter(
        (x) => x
      ),
      length: room.length,
      width: room.width,
      level: room.level,
    }))
    .filter((x) => x.description);
}

export function groupRooms(rooms: PropertyRoom[]): PropertyRoom[][] {
  return Object.values(
    rooms.reduce((prev, curr) => {
      if (!prev[curr.level]) {
        prev[curr.level] = [];
      }
      prev[curr.level].push(curr);
      return prev;
    }, {})
  );
}

export function getSlug(name: string): string {
  return name
    .toLocaleLowerCase()
    .replace(/, /g, '-')
    .replace(/\//g, '-')
    .replace(/\\/g, '-')
    .replace('#', '')
    .replace(/ /g, '-');
}

export const getPropertyClass = (
  propertyClass: string
): Property['propertyClass'] => {
  if (!propertyClass) {
    return 'N/A';
  }
  if (propertyClass === 'CommercialProperty') {
    return 'Commercial';
  }
  if (propertyClass === 'ApartmentProperty') {
    return 'Apartment';
  }
  if (propertyClass === 'ResidentialProperty') {
    return 'Residential';
  }
  return 'N/A';
};

export const transformPropertyTypeString = (
  property: IPropertyResponseItem
) => {
  return getBuildingType(property.details?.propertyType);
};

export const getPropertyName = (property: {
  address: Partial<IPropertyResponseItem['address']>;
}) => {
  const keysToInclude = [
    ['unitNumber', 'streetNumber', 'streetName', 'streetSuffix'],
    ['neighborhood', 'district'],
    ['city'],
    ['state', 'zip'],
  ];

  if (property?.address?.zip) {
    property.address.zip = property.address.zip.replace(' ', '');
  }

  return keysToInclude
    .map((y) =>
      y
        .map((x) => (property?.address?.[x] || '').trim())
        .filter((x) => x)
        .join(' ')
    )
    .filter((x) => x)
    .join(', ');
};

export const getAddressLine1 = (property: IPropertyResponseItem) => {
  const keysToInclude = [
    [
      'unitNumber',
      'streetNumber',
      'streetName',
      'streetSuffix',
      'neighborhood',
      'district',
    ],
  ];

  return keysToInclude
    .map((y) =>
      y
        .map((x) => (property?.address?.[x] || '').trim())
        .filter((x) => x)
        .join(' ')
    )
    .filter((x) => x)
    .join(', ');
};

export const getAddressLine2 = (property: IPropertyResponseItem) => {
  const keysToInclude = [['city'], ['state', 'zip']];

  if (property?.address?.zip) {
    property.address.zip = property.address.zip.replace(' ', '');
  }

  return keysToInclude
    .map((y) =>
      y
        .map((x) => (property?.address?.[x] || '').trim())
        .filter((x) => x)
        .join(' ')
    )
    .filter((x) => x)
    .join(', ');
};

export const padPropertyFeatureValue = (value: number, maxLength = 1) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }
  return value.toString().padStart(maxLength, '0');
};

type FeatureKeys =
  | 'parkingType'
  | 'balcony'
  | 'foundationType'
  | 'exterior'
  | 'style'
  | 'cooling'
  | 'heatingType'
  | 'maintanceFees'
  | 'maintenanceCompany'
  | 'ammenities';

export const FEATURE_TRANSFORM_HANDLERS: {
  [key in FeatureKeys]: (
    value: string,
    property?: IPropertyResponseItem
  ) => string;
} = {
  parkingType: (value: string, _property?: IPropertyResponseItem) => {
    if (!value) return null;
    value = value
      .split(',')
      .filter(Boolean)
      .map((x) => x.trim())
      .map((x) => {
        if (x === 'Undergrnd') return 'Underground';
        if (x === 'Underground Parking') return 'Underground';
        if (x === 'Underground') return x;
        if (x === 'Built-In') return x;
        if (x === 'Attached') return x;
        if (x === 'Detached') return x;
        if (x === 'Other') return null;
        if (x === 'None') return null;
        if (x === 'Carport') return x;
        if (x === 'Surface') return x;
        if (x === 'Covered') return x;
        if (x === 'Outside/Surface') return x;
        if (x === 'Boulevard') return x;
        if (x === 'Street') return x;
        if (x === 'Public') return x;
        if (x === 'Double Detached') return x;
        if (x === 'Lane') return x;
        if (x === 'Plaza') return x;
        if (x === 'Reserved/Assignd') return x;
        if (x === 'Single Detached') return x;
        if (x === 'In/Out') return x;
        if (x === 'Visitor') return x;
        if (x === 'Pay') return x;
        if (x === 'Valet') return x;
        console.warn(
          `Invalid parking type [${x}] - [${_property?.propertyNumber}]`
        );
      })
      .filter(Boolean)
      .join(', ');
    return value;
  },
  balcony: (value: string, _property?: IPropertyResponseItem) => {
    if (!value) return null;
    if (value === 'Open') return value;
    if (value === 'Terr') return null;
    if (value === 'None') return null;
    if (value === 'Jlte') return value;
    if (value === 'Encl') return value;
    console.warn(
      `Invalid balcony type [${value}] - [${_property?.propertyNumber}]`
    );
    return value;
  },
  foundationType: (value: string, _property?: IPropertyResponseItem) => {
    if (!value) return null;
    value = value
      .split(',')
      .filter(Boolean)
      .map((x) => {
        if (x === 'null') return null;
        if (x === 'Poured Concrete') return x;
        if (x === 'None') return x;
        if (x === 'Concrete') return x;
        if (x === 'Concrete Block') return x;
        if (x === 'Unknown') return null;
        if (x === 'Brick') return x;
        if (x === 'Stone') return x;
        if (x === 'Block') return x;
        if (x === 'Other') return x;
        if (x === 'ICF') return x;
        if (x === 'Piers') return x;
        if (x === 'Perimeter Wall') return x;
        if (x === 'Slab') return x;
        if (x === 'Post & Pad') return x;
        if (x === 'Wood') return x;
        if (x === 'Steel Frame') return x;
        if (x === 'Wood Frame') return x;
        if (x === 'Pre-fabricated') return x;
        if (x === 'Concrete Perimeter') return x;
        console.warn(
          `Invalid foundation type [${x}] - [${_property?.propertyNumber}]`
        );
        return x;
      })
      .filter(Boolean)
      .join(', ');
    return value;
  },
  exterior: (value: string, _property?: IPropertyResponseItem) => {
    // value = property.details?.exteriorConstruction1 || property.details?.exteriorConstruction2;
    if (!value) return null;
    return value;
  },
  style: (value: string, _property?: IPropertyResponseItem) => {
    if (!value) return null;
    if (value === '2-Storey') return value;
    if (value === 'Bungalow') return value;
    if (value === 'Apartment') return value;
    if (value === 'Stacked Townhse') return value;
    if (value === '2 Storey') return value;
    if (value === '1 1/2 Storey') return value;
    if (value === 'Agricultural') return value;
    if (value === '3-Storey') return value;
    if (value === 'Bungalow Raised') return value;
    if (value === '1 Storey/Apt') return value;
    if (value === 'Bungalow-Raised') return value;
    if (value === 'Mobile') return value;
    if (value === 'Commercial Condo') return value;
    if (value === 'Sidesplit 3') return value;
    if (value === 'Sidesplit 4') return value;
    if (value === 'Office') return value;
    if (value === 'Industrial Condo') return value;
    if (value === 'Backsplit 4') return value;
    if (value === 'Retail') return value;
    if (value === 'Multi-Level') return value;
    if (value === 'Bungaloft') return value;
    if (value === 'Backsplit 3') return value;
    if (value === 'Backsplit') return value;
    if (value === '1.5 Storey') return value;
    if (value === 'Free Standing') return value;
    if (value === 'Without Property') return value;
    if (value === 'Multi-Use') return value;
    if (value === 'Backsplit 5') return value;
    if (value === 'Multi-Unit') return value;
    if (value === 'Other') return value;
    if (value === 'Industrial') return value;
    if (value === 'Store With Apt/Office') return value;
    if (value === 'With Property') return value;
    if (value === 'Service') return value;
    if (value === '2 1/2 Storey') return value;
    if (value === 'Sidesplit') return value;
    if (value === '3 Storey') return value;
    if (value === 'Bachelor/Studio') return value;
    if (value === 'Multi-Level Split') return value;
    if (value === 'Designated') return value;
    if (value === 'Link') return value;
    if (value === 'Institutional') return value;
    if (value === 'Raw (Outside Official Plan)') return value;
    if (value === 'Sidesplit 5') return value;
    if (value === 'Accommodation') return value;
    if (value === 'Loft') return value;
    if (value === 'Stacked Townhouse') return value;
    if (value === '2.5 Storey') return value;
    if (value === 'Log') return value;
    if (value === 'Cottage') return value;
    if (value === 'Contemporary') return value;
    if (value === 'Chalet') return value;
    if (value === 'A-Frame') return value;
    if (value === 'Highway Commercial') return value;
    if (value === 'Backsplt-All') return value;
    if (value === 'Recreational') return value;
    if (value === 'Garden House') return value;
    if (value === 'Two Story') return value;
    console.warn(`Invalid style [${value}] - [${_property?.propertyNumber}]`);
    return value;
  },
  cooling: (value: string, _property?: IPropertyResponseItem) => {
    if (!value) return null;
    value = value
      .split(',')
      .filter(Boolean)
      .map((x) => {
        if (x === 'Central Air') return x;
        if (x === 'None') return null;
        if (x === 'Wall Unit') return x;
        if (x === 'Other') return x;
        if (x === 'Window Unit') return x;
        if (x === 'Y') return 'Yes';
        if (x === 'N') return null;
        if (x === 'Part') return x;
        if (x === 'Ductless') return x;
        if (x === 'Humidity Control') return x;
        if (x === 'Energy Efficient') return x;
        if (x === 'Radiant Floor') return x;
        console.warn(
          `Invalid cooling type [${x}] - [${_property?.propertyNumber}]`
        );
        return x;
      })
      .filter(Boolean)
      .join(', ');
    return value;
  },
  heatingType: (value: string, _property?: IPropertyResponseItem) => {
    if (!value) return null;
    value = value
      .split(',')
      .filter(Boolean)
      .map((x) => x.trim())
      .map((x) => {
        if (x === 'Baseboard') return x;
        if (x === 'Combo Furnace') return x;
        if (x === 'Elec Forced Air') return x;
        if (x === 'Elec Hot Water') return x;
        if (x === 'Electric') return x;
        if (x === 'Electric Forced Air') return x;
        if (x === 'Electric Hot Water') return x;
        if (x === 'Fan Coil') return x;
        if (x === 'Fireplace') return x;
        if (x === 'Fireplace-Gas') return x;
        if (x === 'Fireplace-Propane') return x;
        if (x === 'Fireplace-Wood') return x;
        if (x === 'Forced Air') return x;
        if (x === 'Forced Air-Propane') return x;
        if (x === 'Gas') return x;
        if (x === 'Gas Forced Air Closd') return x;
        if (x === 'Gas Forced Air Closed') return x;
        if (x === 'Gas Forced Air Open') return x;
        if (x === 'Gas Hot Water') return x;
        if (x === 'Geothermal') return x;
        if (x === 'Heat Pump') return x;
        if (x === 'In-Floor') return x;
        if (x === 'None') return x;
        if (x === 'Oil') return x;
        if (x === 'Oil Forced Air') return x;
        if (x === 'Oil Hot Water') return x;
        if (x === 'Other') return x;
        if (x === 'Propane') return x;
        if (x === 'Propane Gas') return x;
        if (x === 'Radiant') return x;
        if (x === 'Space Heater(s)') return x;
        if (x === 'Wall Furnace') return x;
        if (x === 'Water') return x;
        if (x === 'Water Radiators') return x;
        if (x === 'Wood') return x;
        if (x === 'Woodstove') return x;
        if (x === 'Pellet Stove') return x;
        if (x === 'Ground Source') return x;
        if (x === 'Hot Water-Propane') return x;
        if (x === 'Hot Water-Other') return x;
        if (x === 'Forced Air-Wood') return x;
        if (x === 'Airtight Stove') return x;
        if (x === 'Steam Radiators') return x;
        if (x === 'Outdoor Furnace') return x;
        if (x === 'Radiator') return x;
        if (x === 'Solar') return x;
        if (x === 'Unit Heater') return x;
        if (x === 'Woodburning') return x;
        if (x === 'Gas Well') return x;
        if (x === 'Natural Gas') return x;
        console.warn(
          `Invalid heating type [${x}] - [${_property?.propertyNumber}]`
        );
        return x;
      })
      .filter(Boolean)
      .join(', ');
    return value;
  },
  maintanceFees: (value: string, _property?: IPropertyResponseItem) => {
    if (!value) return null;

    const formatter2 = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${formatCurrency(toNumber(value), formatter2)} Monthly`;
  },
  maintenanceCompany: (value: string, _property?: IPropertyResponseItem) => {
    return value || null;
  },
  ammenities: (_value: string, property?: IPropertyResponseItem) => {
    return property.condominium?.ammenities.filter(Boolean).join(', ');
  },
};

export function transformFeatures(
  property: IPropertyResponseItem
): PropertyFeature[] {
  const result: PropertyFeature[] = [];

  function addFeature(
    name: string,
    value: string | null,
    transformHandler?: (
      value: string | null,
      property?: IPropertyResponseItem
    ) => string
  ) {
    if (transformHandler) {
      value = transformHandler(value, property);
    }

    if (!value) {
      return;
    }

    result.push({ name, value });
  }

  // result.push({
  //   name: 'Property Type',
  //   value: property.details?.propertyType || '',
  // });

  // result.push({
  //   name: 'Building Type',
  //   value: property.class || '',
  // });

  addFeature(
    'Parking Type',
    property.details?.garage,
    FEATURE_TRANSFORM_HANDLERS.parkingType
  );

  // addFeature(
  //   'Total Parking Spaces',
  //   toNumber(property.details?.numParkingSpaces)
  //     ? toNumber(property.details?.numParkingSpaces)?.toString()
  //     : null
  // );

  addFeature(
    'Balcony',
    property.details?.balcony,
    FEATURE_TRANSFORM_HANDLERS.balcony
  );

  // addFeature(
  //   'Foundation Type',
  //   property.details?.foundationType,
  //   FEATURE_TRANSFORM_HANDLERS.foundationType
  // );

  // if (property.details?.landSewer) {
  //   result.push({
  //     name: 'Utility Sewer',
  //     value: property.details.landSewer,
  //   });
  // }

  // addFeature(
  //   'Exterior Finish',
  //   property.details?.exteriorConstruction1,
  //   FEATURE_TRANSFORM_HANDLERS.exterior
  // );

  // if (property.address?.neighborhood) {
  //   result.push({
  //     name: 'Community Name',
  //     value: property.address?.neighborhood,
  //   });
  // }

  // addFeature(
  //   'Style',
  //   property.details?.style,
  //   FEATURE_TRANSFORM_HANDLERS.style
  // );

  addFeature(
    'Cooling',
    property.details?.airConditioning,
    FEATURE_TRANSFORM_HANDLERS.cooling
  );

  // addFeature(
  //   'Heating Type',
  //   property.details?.heating,
  //   FEATURE_TRANSFORM_HANDLERS.heatingType
  // );

  addFeature('Furnishing', property.details?.furnishing, (value) => value);

  // addFeature(
  //   'Maintenance Fees',
  //   toNumber(property.condominium?.fees.maintenance)?.toString(),
  //   FEATURE_TRANSFORM_HANDLERS.maintanceFees
  // );

  // addFeature(
  //   'Maintenance Company',
  //   property.condominium?.propertyMgr,
  //   FEATURE_TRANSFORM_HANDLERS.maintenanceCompany
  // );

  addFeature('Building Amenities', null, FEATURE_TRANSFORM_HANDLERS.ammenities);

  return result;
}
