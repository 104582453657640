import { AbortController } from 'node-abort-controller';
import { ClickAway } from '@/components/ui/click-away';
import { Input } from '@/components/ui/input';
import { useProperty } from '@/context/property';
import useCityResult from '@/hooks/use-city-result';
import BuildingIcon from '@/icons/building';
import LocationIcon from '@/icons/location';
import Spinner from '@/icons/spinner';
import NetworkService from '@/services/network.service';
import { AppObject, Property } from '@/types';
import {
  IPropertyResponseItem,
  sentryCaptureException,
  transformObjectToQuery,
  transformProperty,
} from '@/utility';
import useDebounce from '@/utility/search';
import clsx from 'clsx';
import React, {
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from '@/hooks/use-translation';

const MIN_CHARACTER = 3;

interface AutoCompleteLandmarkProps {
  label?: string;
  placeholder?: string;
  dense?: boolean;
}

interface IPlace {
  key: string;
  type: string;
  description: string;
  name: string;
}

interface IAutocompleteResponse {
  properties: IPropertyResponseItem[];
}

interface IAutocompleteData {
  properties: Property[];
}

const initialState: IAutocompleteData = {
  properties: [],
};

const client = new NetworkService();

interface ICancelToken {
  current: AbortController;
}

export const AutoCompleteLandmark = ({
  label,
  placeholder,
  dense,
}: AutoCompleteLandmarkProps) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');
  const { locale } = useTranslation();

  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const debounceValue = useDebounce(
    value?.trim().length >= MIN_CHARACTER ? value : ''
  );
  const cancelToken = useRef(client.getCancelToken());
  const { filter, filterUpdate, clearRefNeighborhood } = useProperty();
  const [showLoader, setShowLoader] = useState(false);
  const [showData, setShowData] = useState(false);
  const [citiesResult] = useCityResult(debounceValue);

  const cities = useMemo(() => {
    return citiesResult
      .filter((x) => x.key === 'city')
      .map((x) => ({
        description: `${x.name}, ${x.state}`,
        name: x.name,
        state: x.state,
      }));
  }, [citiesResult]);

  const neighborhoods = useMemo(() => {
    return citiesResult
      .filter((x) => x.key === 'neighborhood')
      .map((x) => ({
        description: `${x.name}, ${x.city}, ${x.state}`,
        name: x.name,
        city: x.city,
        state: x.state,
        country: x.country,
      }));
  }, [citiesResult]);

  const hasData =
    cities?.length > 0 ||
    neighborhoods?.length > 0 ||
    data?.properties?.length > 0;

  useEffect(() => {
    if (!loading || !show) {
      setShowLoader(false);
      return;
    }

    const timer = setTimeout(() => {
      setShowLoader(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [value, loading, show]);

  useEffect(() => {
    const shouldShow =
      Boolean(debounceValue) &&
      Boolean(value) &&
      value.length >= MIN_CHARACTER &&
      (hasData || !loading);

    if (!shouldShow) {
      setShowData(false);
      return;
    }

    const timer = setTimeout(() => {
      setShowData(true);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [debounceValue, value, hasData, loading]);

  useEffect(() => {
    setValue(filter.city || filter.neighborhood || '');
  }, [filter.city, filter.neighborhood]);

  const searchByLandmark = useCallback(
    async (
      debounceValue: string,
      cancelToken?: ICancelToken
    ): Promise<IAutocompleteData> => {
      if (cancelToken?.current) {
        cancelToken.current.abort();
      }
      cancelToken.current = client.getCancelToken();
      if (!debounceValue) {
        return initialState;
      }

      const query: AppObject = {
        q: debounceValue,
        status: 'A',
      };

      if (filter.type === 'sold' || filter.type === 'leased') {
        query.status = 'U';
        query.lastStatus = filter.type === 'sold' ? 'Sld' : 'Lsd';
        query.type = filter.type === 'sold' ? 'sale' : 'lease';
      } else {
        query.type = filter.type;
      }

      query.include = ['property'];

      const response = await client.get<IAutocompleteResponse>(
        `/api/listing/autocomplete?${transformObjectToQuery(query)}`,
        cancelToken.current.signal
      );

      const properties = response.properties.map((item) =>
        transformProperty(item, locale)
      );

      return {
        properties,
      };
    },
    [filter.type]
  );

  useEffect(() => {
    if (!show) return;
    (async () => {
      try {
        setLoading(true);
        const response = await searchByLandmark(debounceValue, cancelToken);
        setData(response);
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.error(err);
          sentryCaptureException(err);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [debounceValue, searchByLandmark, show]);

  const toggleDropdown = () => {
    setShow((prev) => !prev);
  };

  const openDropdown = () => {
    if (!show) {
      setShow(true);
    }
  };

  const handleClickAway = () => {
    onBlur();
    if (show) {
      setShow(false);
    }
  };

  const handleCityChange = (e: React.MouseEvent, place: IPlace) => {
    e.preventDefault();
    e.stopPropagation();
    toggleDropdown();
    setValue(place.name);
    const cityName = place.type === 'city' ? place.name : null;
    const neighborhoodName = place.type === 'neighborhood' ? place.name : null;
    filterUpdate({
      city: cityName,
      neighborhood: neighborhoodName,
    });
    clearRefNeighborhood();
  };

  const onBlur = () => {
    if (!show) return;
    if (!value) {
      filterUpdate({
        city: null,
        neighborhood: null,
      });
    }
  };

  const onFocus = () => {
    if (!show) {
      setShow(true);
    }
  };

  const handleLinkClick = () => {
    toggleDropdown();
    setValue('');
    if (filter.city || filter.neighborhood) {
      filterUpdate({
        city: null,
        neighborhood: null,
      });
    }
  };

  return (
    <ClickAway
      onClickAway={handleClickAway}
      className="relative w-full"
      value={show}
    >
      {!!label && (
        <div className="mb-1 text-xs text-gray-400">
          <span>{label}</span>
        </div>
      )}

      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={onFocus}
        onClick={openDropdown}
        onBlur={onBlur}
        placeholder={placeholder}
        margin={dense ? 'dense' : 'normal'}
        variant="outlined"
      />
      {showLoader && (
        <div className="absolute top-0 right-2 bottom-0 flex items-center justify-center">
          <Spinner className="h-5 w-5 text-primary-500 spin" />
        </div>
      )}
      {showData && (
        <ul
          className={clsx(
            'origin-top-right absolute -left-20 -right-20 md:left-0 md:right-0 mt-1 bg-white shadow-lg border rounded z-50 overflow-auto',
            { hidden: !show },
            { block: show }
          )}
          style={{ maxHeight: '35rem' }}
        >
          {!hasData ? (
            <li className="w-full cursor-not-allowed transform duration-200 px-4 py-2">
              <p className="text-gray-400 text-center py-4">No data found</p>
            </li>
          ) : (
            <>
              {!!cities.length && (
                <HeaderItem title="Places" Icon={LocationIcon} />
              )}
              {cities.map((item) => (
                <PlaceItem
                  key={item.description}
                  place={{
                    key: `${item.name}-${item.state}`,
                    name: item.name,
                    type: 'city',
                    description: item.description,
                  }}
                  onClick={handleCityChange}
                />
              ))}
              {neighborhoods.map((item) => (
                <PlaceItem
                  key={item.description}
                  place={{
                    key: `${item.name}-${item.city}-${item.state}`,
                    name: item.name,
                    type: 'neighborhood',
                    description: item.description,
                  }}
                  onClick={handleCityChange}
                />
              ))}

              {!!data.properties.length && (
                <HeaderItem title="Listings" Icon={BuildingIcon} />
              )}
              {data.properties.map((property) => (
                <ListingItem
                  key={property.propertyNumber}
                  property={property}
                  onClick={handleLinkClick}
                />
              ))}
            </>
          )}
        </ul>
      )}
    </ClickAway>
  );
};

type IconComponentFn = (props: SVGProps<SVGSVGElement>) => JSX.Element;

function HeaderItem(props: { title: string; Icon: IconComponentFn }) {
  const Icon = props.Icon;
  return (
    <li className="w-full cursor-not-allowed transform duration-200 text-gray-600 px-4 py-2 font-semibold text-base flex items-center bg-gray-100">
      <Icon className="h-4 w-4" />{' '}
      <span className="inline-block ml-2">{props.title}</span>
    </li>
  );
}

function PlaceItem({
  place,
  onClick,
}: {
  place: IPlace;
  onClick: (e: React.MouseEvent<HTMLDivElement>, city: IPlace) => void;
}) {
  return (
    <li
      className="w-full  cursor-pointer transform duration-200  px-4 py-2 hover:bg-gray-50"
      key={place.key}
    >
      <div
        onClick={(e) => onClick(e, place)}
        className="flex items-center justify-between space-x-4 "
      >
        <div className="flex flex-row items-center space-x-4 flex-1">
          <div className="text-gray-800 font-bold text-sm flex-1">
            {place.description}
          </div>
          <div className="text-gray-400 text-sm capitalize">{place.type}</div>
        </div>
      </div>
    </li>
  );
}

function ListingItem({
  property,
  onClick,
}: {
  property: Property;
  onClick: (item: Property) => void;
}) {
  return (
    <li
      className="w-full  cursor-pointer transform duration-200  px-4 py-2 hover:bg-gray-50"
      key={property.propertyNumber}
    >
      <a
        href={`/detail/${property.id}/${property.slug}`}
        onClick={() => onClick(property)}
        target="_blank"
        rel="noreferrer"
      >
        <div className="flex items-center justify-between space-x-4">
          <div className="flex flex-row items-center space-x-4 flex-1">
            <div className="text-gray-800 font-bold text-sm flex-1">
              {property.name}
            </div>
            <div className="text-gray-400 text-sm">
              {property.propertyNumber}
            </div>
          </div>
        </div>
      </a>
    </li>
  );
}
