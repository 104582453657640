import Config from '@/config';

// Defining gtag instance
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: (type: string, action: any, params: any) => void;
  }
}

export interface AnalyticsServiceConfig {
  gtagId: string;
}

class AnalyticsService {
  public gtagId: string;

  constructor({ gtagId }: AnalyticsServiceConfig) {
    this.gtagId = gtagId;
  }

  public pageview(url: string) {
    window.gtag('config', Config.GOOGLE_ANALYTICS_TAG_ID, {
      page_path: url,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async event(eventName: string, params: any) {
    window.gtag('event', eventName, params);
  }
}

export default new AnalyticsService({
  gtagId: Config.GOOGLE_ANALYTICS_TAG_ID,
});
