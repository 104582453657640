// import moment from 'moment';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export const FORMAT_YEAR = 'YYYY';
export const FORMAT_DATE1 = 'DD MMM YYYY';
export const FORMAT_DATE2 = 'DD MMM YYYY';
export const FORMAT_DATE3 = 'DD MMMM YYYY';
export const FORMAT_DATE4 = 'YYYY-MM-DD';
export const FORMAT_DATETIME2 = 'MMM DD, YYYY hh:mm A';

export const convertToModernDatePickerFormat = (date: Date) => {
  return {
    year: dayjs(date).year(),
    month: dayjs(date).month() + 1,
    day: dayjs(date).date(),
  };
  // return {
  //   year: Number(moment(date).format('YYYY')),
  //   month: Number(moment(date).format('MM')),
  //   day: Number(moment(date).format('DD')),
  // };
};

export const convertModernDateInputToDate = (
  value?: {
    year: number;
    month: number;
    day: number;
  } | null
): Date => {
  return new Date(`${value?.year}-${value?.month}-${value?.day}`);
  // return moment(`${value?.month}-${value?.day}-${value?.year}`).toDate();
};

export const fromNowInDays = (date: string | Date): string => {
  return dayjs(date).fromNow();
  // const value = moment(date).startOf('day');
  // const now = moment();

  // if (now.diff(value, 'days') === 0) {
  //   return 'today';
  // }

  // const todayStartOfDay = moment().startOf('day');

  // value.add(now.diff(todayStartOfDay, 'seconds'), 'seconds');

  // return value.fromNow();
};

export function formatDate(date: string | Date, format: string): string {
  return dayjs(date).format(format);
  // return moment(date).format(format);
}

export function dateDiffInDays(
  date1: string | Date,
  date2: string | Date
): number {
  return dayjs(date1).diff(dayjs(date2), 'days');
  // return moment(date1).diff(moment(date2), 'days');
}

export function subtractDays(date: string | Date, days: number): Date {
  return dayjs(date).subtract(days, 'days').toDate();
  // return moment(date).subtract(days, 'days').toDate();
}

export function subtractMonths(date: string | Date, months: number): Date {
  return dayjs(date).subtract(months, 'months').toDate();
  // return moment(date).subtract(months, 'months').toDate();
}

export function subtractYears(date: string | Date, years: number): Date {
  return dayjs(date).subtract(years, 'years').toDate();
  // return moment(date).subtract(years, 'years').toDate();
}

export function addDays(date: string | Date, days: number): Date {
  return dayjs(date).add(days, 'days').toDate();
}
