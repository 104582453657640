import { Link } from '@/components/ui/link';
import { SocialLinks } from '../social-links';

import InfoJson from '@/config/info.json';

import { FORMAT_YEAR, formatDate } from '@/utility/date';
// import CitySection from './city-section';
import { useTranslation } from '@/hooks/use-translation';

export function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="flex items-center relative mt-4 px-4 lg:px-8 mb-10 justify-center">
        <div className="grid md:grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="w-full">{/* <CitySection /> */}</div>
          <div>
            <div className="flex items-center justify-center flex-col h-full">
              <h2 className="text-3xl">Degaan</h2>
              <a
                href={`tel: ${InfoJson.contactNo}`}
                title={`Call : ${InfoJson.contactNo}`}
                className="my-2 px-2"
              >
                <p className="text-gray-600">{InfoJson.contactNo}</p>
              </a>
              <a
                href={`mailto:${InfoJson.email}`}
                title={`Email @ ${InfoJson.email}`}
                className="mb-2 px-2"
              >
                <p className="text-gray-600">{InfoJson.email}</p>
              </a>
              <SocialLinks className="mt-4 text-secondary-500" />
              <div className="max-w-xs text-center mt-6 leading-6">
                <p style={{ whiteSpace: 'nowrap' }}>
                  {InfoJson.footeraddress1}
                  <br />
                  {InfoJson.footeraddress2}
                  <br />
                  {InfoJson.footeraddress3}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col">
            <div className="relative h-36 md:h-40 w-80 md:w-96"></div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 text-white py-10">
        <div className="grid md:grid-cols-2 px-8 space-y-2">
          <div className="text-center md:text-left">
            <p>
              {t('©Copyright {year} {name} all Rights Reserved', {
                year: formatDate(new Date(), FORMAT_YEAR),
                name: 'Degaan',
              })}
            </p>
          </div>
          <div className="flex items-center justify-center md:justify-end space-x-3">
            <Link href="/privacy" prefetch={false}>
              {t('Privacy Policy')}
            </Link>
            <span>/</span>
            <Link href="/tos" prefetch={false}>
              &nbsp;{t('Terms & Conditions')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
