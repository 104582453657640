import React from 'react';
import { NextSeo } from 'next-seo';

import { Footer } from '@/components/common';
import { DefaultNavbar } from '@/components/common/navbar';
import { useRouter } from 'next/dist/client/router';
import { Provider } from 'react-redux';
import SEO from '@/config/seo.json';
import { CookieManager } from '@/components/ui/cookie-manager';
import { SignInModal } from '@/components/modals';
import { AppProvider } from '@/context/root/app-provider';
import { paths } from './constant';
import store from '@/store';
import FloatingAction from '@/components/floating-action';
import DialogProvider from '@/components/dialog-provider';

export function DefaultLayout({ children }) {
  const { pathname } = useRouter();
  return (
    <Provider store={store}>
      <div className="relative">
        <NextSeo {...SEO} {...(paths[pathname] || { tilte: 'Home' })} />
        <AppProvider>
          {!pathname.startsWith('/properties') && <DefaultNavbar />}
          <SignInModal />
          {children}
          <Footer />

          {/* <BotIcon /> */}
          <CookieManager />
          <FloatingAction />
          <DialogProvider />
        </AppProvider>
      </div>
    </Provider>
  );
}
