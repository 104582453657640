import en from '@/locales/en/common.json';
import so from '@/locales/so/common.json';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

type Parameters = Record<string, string | number>;

export function translate(
  locale: string,
  key: string,
  parameters?: Parameters
) {
  let db: { [key: string]: string } = null;

  switch (locale) {
    case 'en':
      db = en;
      break;
    case 'so':
      db = so;
      break;
    default:
      // console.warn(`Missing translation for locale: ${locale}`);
      db = en;
  }

  let output = key;

  if (db[key]) {
    output = db[key];
  } else {
    if (locale === 'so') {
      console.warn(`Missing translation for key: ${key}, lang: ${locale}`);
    }
  }

  if (parameters) {
    Object.keys(parameters).forEach((key) => {
      output = output.replace(`{${key}}`, String(parameters[key]));
    });
  }

  return output;
}

export function useTranslation() {
  const { locale } = useRouter();

  const t = useCallback(
    (key: string, parameters?: Parameters) =>
      translate(locale, key, parameters),
    [locale]
  );

  return {
    t,
    locale,
  };
}
