import { useEffect, useRef } from 'react';
import { ACTION } from '../action';
import { DEFAULT_ZOOM } from '../constant';
import { IAction, IMapPosition, IPoint, IState } from '../types';

export function useGeoLocation(
  state: IState,
  dispatch: React.Dispatch<IAction>
) {
  const previousRef = useRef(state.map.position);
  const viewRef = useRef(state.view);

  useEffect(() => {
    previousRef.current = state.map.position;
  }, [state.map.position]);

  useEffect(() => {
    viewRef.current = state.view;
  }, [state.view]);

  useEffect(() => {
    if (!state.initialized) return;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // update current location show on map
          dispatch({
            type: ACTION.SET_MAP_CURRENT_LOCATION,
            payload: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            } as IPoint,
          });

          // skip update default location
          if (state.map.skipCurrentLocation) return;

          if (
            previousRef.current.lat === position.coords.latitude &&
            previousRef.current.lng === position.coords.longitude
          ) {
            return;
          }

          dispatch({
            type: ACTION.SET_MAP_DEFAULT_POSITION,
            payload: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              zoom: DEFAULT_ZOOM,
            } as IMapPosition,
          });

          if (viewRef.current === 'map') {
            dispatch({
              type: ACTION.SET_MAP_POSITION,
              payload: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                zoom: DEFAULT_ZOOM,
              } as IMapPosition,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [state.initialized, state.map.skipCurrentLocation, dispatch]);
}
