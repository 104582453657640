import useRouteQuery from '@/hooks/use-route-query';
import { Property } from '@/types';
import { queryToFilter } from '@/utility';
import { useEffect, useReducer, useState } from 'react';
import { ACTION } from './action';
import { DEFAULT_ZOOM, initialState } from './constant';
import { useDataLoader } from './hooks/use-data-loader';
import { useGeoLocation } from './hooks/use-geo-location';
import { useMapDataLoader } from './hooks/use-map-data-loader';
import { useStateUpdater } from './hooks/use-state-updater';
import { useUrlUpdater } from './hooks/use-url-updater';
import { PropertyContext } from './property-context';
import { reducer } from './reducer';
import { IPagination, IRefPropertyInfo, IState } from './types';

interface IPropertyProviderProps {
  pagination: IPagination;
  properties: Property[];
}

export const PropertyProvider: React.FC<IPropertyProviderProps> = ({
  children,
  properties,
  pagination,
}) => {
  const [isQueryLoaded, setQueryLoaded] = useState(false);
  const { query, router } = useRouteQuery();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    data: {
      ...initialState.data,
      pagination,
      properties,
    },
  });

  // useEffect(() => {
  //   console.log('state updated', state);
  // }, [state]);

  useEffect(() => {
    if (isQueryLoaded) return; // load only once
    setQueryLoaded(true);

    const isMapView = query.view === 'map';
    let skipCurrentLocation = false;
    let refPropertyInfo: IRefPropertyInfo = null;
    const defaultPosition = { ...initialState.map.defaultPosition };

    if (isMapView && query.lat && query.lng) {
      skipCurrentLocation = true;
      defaultPosition.lat = Number(query.lat);
      defaultPosition.lng = Number(query.lng);
      defaultPosition.zoom = query.zoom ? Number(query.zoom) : DEFAULT_ZOOM;
    }

    if (isMapView && query.refpropertynumber && query.reflat && query.reflng) {
      refPropertyInfo = {
        propertyNumber: query.refpropertynumber as string,
        lat: Number(query.reflat),
        lng: Number(query.reflng),
        neighborhood: query.refneighborhood as string,
      };
    }

    const filter = {
      ...state.filter,
      ...queryToFilter(query),
    };

    const cityslug = router.query.cityslug as string;
    const neighborhoodslug = router.query.neighborhoodslug as string;

    if (neighborhoodslug) {
      filter.neighborhood = neighborhoodslug;
    }
    if (cityslug) {
      const city = cityslug.replace('-real-estate', '');
      filter.city = city;
    }

    if (!filter.type) {
      filter.type = 'sale';
    }

    dispatch({
      type: ACTION.UPDATE_STATE,
      payload: {
        ...state,
        initialized: true,
        // view: query.view || localStorage.getItem('selectedView') || 'list',
        view: query.view || 'list',
        filter,
        map: {
          ...state.map,
          skipCurrentLocation,
          position: {
            lat: isMapView ? defaultPosition.lat : null,
            lng: isMapView ? defaultPosition.lng : null,
            zoom: isMapView ? defaultPosition.zoom : null,
          },
          defaultPosition,
          refPropertyInfo,
          properties: [],
          refreshing: false,
        },
      } as IState,
    });
  }, [query, router, isQueryLoaded, dispatch, state]);

  useDataLoader(state, dispatch);
  useMapDataLoader(state, dispatch);
  useUrlUpdater(state);
  useStateUpdater(state, dispatch);
  useGeoLocation(state, dispatch);

  return (
    <PropertyContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};
