import { usePropertyContext } from './use-property-context';

export const usePropertyData = () => {
  const { state } = usePropertyContext();
  return {
    view: state.view,
    filter: state.filter,
    data: state.data,
    map: state.map,
    state,
  };
};
