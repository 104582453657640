import Config from '@/config';
import LocalStorage from '@/services/localstorage';
import { useEffect, useState } from 'react';
import { Button } from '../button';

export const CookieManager = () => {
  const [cookie, setCookie] = useState(true);
  useEffect(() => {
    setCookie(!!LocalStorage.get(Config.COOKIE_KEY));
  }, []);

  if (cookie) {
    return null;
  }

  const handleAgreeCookie = () => {
    LocalStorage.set(Config.COOKIE_KEY, JSON.stringify(true));
    setCookie(true);
  };

  return (
    <aside className="flex fixed bottom-0 px-4 py-6 bg-white right-0 left-0 items-center justify-center md:space-x-4 border-t z-50 flex-wrap">
      <div className="max-w-3xl">
        <p className="text-sm md:text-base">
          <b>Notice:</b> We uses cookies to provide necessary website
          functionality, improve your experience and analyze our traffic. By
          using our website, you agree to our Privacy Policy and our cookies
          usage.
        </p>
      </div>
      <Button className="mt-2 md:mt-0" onClick={handleAgreeCookie}>
        OK
      </Button>
    </aside>
  );
};
